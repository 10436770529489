/**
 * Created by Nikita Besshaposhnikov on 16.07.15.
 */

/**
 * This namespace contains functions for working with backgrounds.
 * @namespace
 */
dd.backgroundUtils = {

	_levelBackgroundParameters: {},

	generateBackground: function()
	{
		var layer = new cc.LayerColor(dd.backgroundUtils.BACKGROUND_COLOR);

		var screenSize = dd.settings.getScreenSize();
		var centerX = screenSize.width / 2;
		var centerY = screenSize.height / 2;

		layer.setContentSize(screenSize);

		var centerSpr = new cc.Sprite("Background/main_background.jpg");

		centerSpr.setAnchorPoint(cc.p(0.5, 0.5));
		centerSpr.setPosition(centerX, centerY);
		centerSpr.setScale(Math.max(
			screenSize.width / centerSpr.width,
			screenSize.height / centerSpr.height));

		layer.addChild(centerSpr);

		return layer;
	},

	generateGameBackground: function()
	{
		var layer = new cc.LayerColor(dd.backgroundUtils.BACKGROUND_COLOR);

		var screenSize = dd.settings.getScreenSize();
		var centerX = screenSize.width / 2;
		var centerY = screenSize.height / 2;

		layer.setContentSize(screenSize);

		var centerSpr = new cc.Sprite("Background/TableBackground.png");

		centerSpr.setAnchorPoint(cc.p(0.5, 0.5));
		centerSpr.setPosition(centerX, centerY);
		centerSpr.setScale(screenSize.height / centerSpr.height);

		layer.addChild(centerSpr);

		return layer;
	}
};

dd.backgroundUtils.BACKGROUND_COLOR = cc.color(100, 61, 71);
dd.backgroundUtils.STARS_INTENSITY = 150;
dd.backgroundUtils.MAX_SKIP_COUNT = 3;
dd.backgroundUtils.ITEMS_INTENSITY = 4;
dd.backgroundUtils.WIDTH_DELTA = 20;
