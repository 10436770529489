/**
 * Created by Vika O on 01.11.2024.
 */

/**
 * This class represents a "Map" container
 * @class
 * @extends dd.Class
 */
dd.data.MapClass = dd.Class.extend(/** @lends dd.data.MapClass */{
	typeName: "map",

	ctor: function() { this._super(); }
});
