/**
 * Created by Kirill Mashchenko on 23.08.18.
 */

/**
 * @class Dialog for changing name of the world.
 * @extends dui.OvalLayout
 * @constructor
 * @param {String} labelStr String of input label.
 * @param {String} defaultValue Default value of input.
 * @param {String} placeholder Placeholder of input.
 */
dui.ChangeWorldNameDialog = MovingAfterKeyboardLayout.extend(/** @lends dui.ChangeWorldNameDialog#*/{

	_input: null,
	_inputDescription: null,
	_target: null,
	_okCallback: null,
	_cancelCallback: null,

	ctor: function(placeholder)
	{
		this._super(null, null, dui.OvalLayout.Type.SLIM);

		this.setLayoutType(ccui.Layout.RELATIVE);

		var width = 0;
		var height = 0;

		var label = new ccui.Text(LocalizedString("EnterNewName"), dd.settings.fontName, dd.settings.fontSize);

		var labelAlign = new ccui.RelativeLayoutParameter();
		labelAlign.setAlign(ccui.RelativeLayoutParameter.PARENT_TOP_CENTER_HORIZONTAL);
		labelAlign.setMargin(0, dui.OvalLayout.STANDARD_BORDER_RADIUS, 0, 0);
		labelAlign.setRelativeName("label");
		label.setLayoutParameter(labelAlign);
		this.addChild(label);

		width = label.width;
		height += label.height;

		this._input = new dui.TextInput(world.name, LocalizedString("NewName"), 20, dui.ChangeWorldNameDialog.INPUT_WIDTH);

		var inputAlign = new ccui.RelativeLayoutParameter();
		inputAlign.setAlign(ccui.RelativeLayoutParameter.LOCATION_BELOW_CENTER);
		inputAlign.setMargin(0, dui.TextInputDialog.SEPARATOR, 0, 0);
		inputAlign.setRelativeName("input");
		inputAlign.setRelativeToWidgetName("label");
		this._input.setLayoutParameter(inputAlign);

		width = Math.max(width, this._input.width);
		height += this._input.height + dui.TextInputDialog.SEPARATOR;

		this.addChild(this._input);

		var descriptionLabel = new ccui.Text(LocalizedString("EnterNewDescription"), dd.settings.fontName, dd.settings.fontSize);

		var descriptionLabelAlign = new ccui.RelativeLayoutParameter();
		descriptionLabelAlign.setAlign(ccui.RelativeLayoutParameter.LOCATION_BELOW_CENTER);
		descriptionLabelAlign.setMargin(0, dui.TextInputDialog.SEPARATOR, 0, 0);
		descriptionLabelAlign.setRelativeName("descriptionLabel");
		descriptionLabelAlign.setRelativeToWidgetName("input");
		descriptionLabel.setLayoutParameter(descriptionLabelAlign);
		this.addChild(descriptionLabel);

		width = Math.max(descriptionLabel.width, width);
		height += descriptionLabel.height + dui.TextInputDialog.SEPARATOR;

		this._inputDescription = new dui.TextInput(
			world.description,
			LocalizedString("Description"),
			20,
			dui.ChangeWorldNameDialog.INPUT_WIDTH,
			dui.ChangeWorldNameDialog.DESCRITPION_HEIGHT
		);

		var inputDescriptionAlign = new ccui.RelativeLayoutParameter();
		inputDescriptionAlign.setAlign(ccui.RelativeLayoutParameter.LOCATION_BELOW_CENTER);
		inputDescriptionAlign.setMargin(0, dui.ChangeWorldNameDialog.SEPARATOR, 0, 0);
		inputDescriptionAlign.setRelativeName("inputDescription");
		inputDescriptionAlign.setRelativeToWidgetName("descriptionLabel");
		this._inputDescription.setLayoutParameter(inputDescriptionAlign);

		width = Math.max(width, this._inputDescription.width);
		height += this._inputDescription.height + dui.ChangeWorldNameDialog.SEPARATOR;

		this.addChild(this._inputDescription);

		this.setInputAttach(this._input);
		this.setInputAttach(this._inputDescription);

		var buttonLayout = new ccui.HBox();

		var buttonLayoutAlign = new ccui.RelativeLayoutParameter();
		buttonLayoutAlign.setAlign(ccui.RelativeLayoutParameter.LOCATION_BELOW_CENTER);
		buttonLayoutAlign.setMargin(0, dui.ChangeWorldNameDialog.SEPARATOR, 0, 0);
		buttonLayoutAlign.setRelativeToWidgetName("inputDescription");
		buttonLayout.setLayoutParameter(buttonLayoutAlign);

		this.addChild(buttonLayout);

		var okButton = new dui.InterfaceButton(this._ok, this, LocalizedString("OkButton"));
		buttonLayout.addChild(okButton);

		var cancelButton = new dui.InterfaceButton(this._cancel, this, LocalizedString("CancelButton"));
		var cancelMargin = new ccui.LinearLayoutParameter();
		cancelMargin.setMargin(dui.ChangeWorldNameDialog.SEPARATOR, 0, 0, 0);
		cancelButton.setLayoutParameter(cancelMargin);

		buttonLayout.addChild(cancelButton);

		buttonLayout.setContentSize(okButton.getContentSize().width + cancelButton.getContentSize().width + 10,
			okButton.getContentSize().height);

		width = Math.max(width, buttonLayout.width);
		height += buttonLayout.height + 2 * dui.ChangeWorldNameDialog.SEPARATOR + dui.OvalLayout.STANDARD_BORDER_RADIUS;

		this.setContentSizeWithBorder(width + 2 * dui.OvalLayout.STANDARD_BORDER_RADIUS, height);

		var loadingLayer = new LoadingLayer(false);
		loadingLayer.setPosition((this.width - dd.settings.getScreenSize().width) / 2,
			(this.height - dd.settings.getScreenSize().height) / 2);

		this.addChild(loadingLayer, -100);

		var listener = cc.EventListener.create({
			event: cc.EventListener.TOUCH_ONE_BY_ONE,
			swallowTouches: true,
			onTouchBegan: function()
			{
				return true;
			},
			onTouchMoved: function() {},
			onTouchEnded: function() {}
		});

		cc.eventManager.addListener(listener, this);
	},

	/**
     * Set callbacks for dialog.
     * @param target Target for callbacks.
     * @param {dui.ChangeWorldNameDialog~okCallback} okCallback On "OK" button pressed.
     * @param {dui.ChangeWorldNameDialog~cancelCallback} cancelCallback On "Cancel" button pressed.
     */
	setCallback: function (target, okCallback, cancelCallback)
	{
		this._target = target;
		this._okCallback = okCallback;
		this._cancelCallback = cancelCallback;
	},

	_ok: function(sender)
	{
		this.closeAttachKeyboard();

		this._okCallback.call(this._target, this._input.getString(), this._inputDescription.getString());
		this.removeFromParent();
	},

	_cancel: function(sender)
	{
		this.closeAttachKeyboard();

		if (this._cancelCallback)
			this._cancelCallback.call(this._target);
		this.removeFromParent();
	}
});

dui.ChangeWorldNameDialog.INPUT_WIDTH = 350;
dui.ChangeWorldNameDialog.DESCRITPION_HEIGHT = 250;

dui.ChangeWorldNameDialog.SEPARATOR = 8.0;
