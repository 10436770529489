dui.Timer = ccui.Layout.extend(/** @lends dui.Timer# */{

    _time: 0,
    _currentTime: 0,
    _extraTime: 0,

    _sprite: null,
    _spriteType: null,

    ctor: function(time)
    {
        this._super();

        this._time = time;

        let background = new cc.Sprite(dd.spriteUtils.getTimerSprite("grey"));
        background.setAnchorPoint(cc.p(0,0));
        background.setPosition(cc.p(0,0));

        this.addChild(background, -10);
        this.setContentSize(background.width, background.height);

        this._extraTime = 5 / background.width * this._time;
        this._time += this._extraTime;

        this._spriteType = dui.Timer.SpriteTypes.Green;

        this._sprite = new cc.Sprite(dd.spriteUtils.getTimerSprite(this._spriteType));
        this._sprite.setAnchorPoint(cc.p(0,0));
        this._sprite.setPosition(cc.p(0,0));

        this.addChild(this._sprite);

        this.scheduleUpdate();
    },

    restart: function()
    {
        this._currentTime = this._time;
    },

    setTime: function(time)
    {
        this._currentTime = time + this._extraTime;
    },

    stop: function()
    {
        this.removeFromParent();
        this.unscheduleUpdate();
    },

    update: function(dt)
    {
        this._currentTime -= dt;

        if (this._currentTime <= this._extraTime)
            this.stop();

        if (this._currentTime > this._time * 2 / 3
            && this._spriteType !== dui.Timer.SpriteTypes.Green)
        {
            this._spriteType = dui.Timer.SpriteTypes.Green;
            this._updateSprite();
        }
        else if (this._currentTime < this._time * 2 / 3
            && this._spriteType === dui.Timer.SpriteTypes.Green)
        {
            this._spriteType = dui.Timer.SpriteTypes.Yellow;
            this._updateSprite();
        }
        else if (this._currentTime < this._time / 3
            && this._spriteType === dui.Timer.SpriteTypes.Yellow)
        {
            this._spriteType = dui.Timer.SpriteTypes.Red;
            this._updateSprite();
        }

        this._scaleSprite();
    },

    _scaleSprite: function()
    {
        this._sprite.setScaleX(this._currentTime / this._time);
    },

    _updateSprite: function()
    {
        this._sprite.removeFromParent();

        this._sprite = new cc.Sprite(dd.spriteUtils.getTimerSprite(this._spriteType));
        this._sprite.setAnchorPoint(cc.p(0,0));
        this._sprite.setPosition(cc.p(0,0));

        this.addChild(this._sprite);
    }
});

dui.Timer.UPDATE_INTERVAL = 1;

dui.Timer.SpriteTypes = {
    Green: "green",
    Yellow: "yellow",
    Red: "red"
};


dui.NumberLayer = ccui.Layout.extend(/** @lends dui.NumberLayer# */{

    _text: null,

    ctor: function(number)
    {
        this._super();

        this.setBackGroundImage("Background/NumberBackground.png");
        this.setBackGroundImageScale9Enabled(true);
        this.setCascadeOpacityEnabled(true);

        this.setContentSize(dui.NumberLayer.SIDE_WIDTH, dui.NumberLayer.SIDE_WIDTH);
        this.setLayoutType(ccui.Layout.RELATIVE);

        this._text = new ccui.Text(number, dd.settings.fontName, dd.settings.fontSize);

        let textAlign = new ccui.RelativeLayoutParameter();
        textAlign.setAlign(ccui.RelativeLayoutParameter.PARENT_BOTTOM_CENTER_HORIZONTAL);
        textAlign.setMargin(0, 0, 0, dui.NumberLayer.SIDE_WIDTH / 2 - this._text.height / 2 - 2);
        this._text.setLayoutParameter(textAlign);

        this.addChild(this._text);
    },

    updateText: function(number)
    {
        this._text.setString(number);
    }
});

dui.NumberLayer.SIDE_WIDTH = 36;
