/**
 * Created by danilaeremin on 12.08.15.
 */

/**
 * @class This class is wrapper for scrollview for handling touch events
 * @extends ccui.ScrollView
 */
var ScrollViewTouchWrapper = dd.ScrollViewTouchWrapper || ccui.ScrollView.extend({

	_clickDepth: 0,

	_callback: null,

	ctor: function (clickDepth)
	{
		this._super();

		this._clickDepth = clickDepth;

		this.setTouchEnabled(true);
	},

	/**
     * Sets callback for click
     * @param {dui.SelectTableView~selectCallback} callback
     */
	setClickEventCallback: function(callback)
	{
		this._callback = callback;
	},

	/**
     * Intercept touch event, handle its child's touch event.
     * @param {Number} event
     * @param {ccui.Widget} sender
     * @param {cc.Touch} touch
     */
	interceptTouchEvent: function (event, sender, touch)
	{
		ccui.ScrollView.prototype.interceptTouchEvent.call(this, event, sender, touch);

		if (!this._touchEnabled)
			return;

		if (event === ccui.Widget.TOUCH_ENDED)
		{
			var parent = sender;
			var clickDepth = 0;

			while (parent)
			{
				++clickDepth;
				if (parent && parent.getParent() === this._innerContainer)
					break;

				parent = parent.getParent();
			}
			if (clickDepth <= this._clickDepth && sender.isHighlighted() && this._callback)
				this._callback(sender);
		}
	}

});

/**
 * @class This class represents a table with fixed cell size.<br/>
 * Supported operations with rows and cols.
 * @extends ccui.Layout
 * @constructor
 * @param {cc.Size} cellSize Size of cells.
 * @param {cc.Size} cellSeparator Dimensions of cell separators.
 * @param {Number} [rowCount = 1] Number of rows.
 * @param {Number} [columnCount = 1] Number of columns.
 */
dui.TableView = ccui.Layout.extend(/** @lends dui.TableView# */{
	_cellSize: null,
	_cellSeparator: null,

	_rowCount: 1,
	_columnCount: 1,

	_fitObjectSizes: false,

	_cells: [],

	_placeholderRows: [],

	_fixed: true,
	_scroll: null,
	_innerLayout: null,

	ctor: function (cellSize, cellSeparator, rowCount, columnCount, fixed)
	{
		this._super();

		if (fixed === undefined)
			fixed = true;

		this._fixed = fixed;

		this._cellSize = cellSize;
		this._cellSeparator = cellSeparator;

		if (columnCount !== undefined)
			this._columnCount = columnCount;
		else
			this._columnCount = 1;

		if (rowCount !== undefined)
			this._rowCount = rowCount;
		else
			this._rowCount = 1;

		this._cells = [];
		this._placeholderRows = [];

		this._fitObjectSizes = false;

		for (var i = 0; i < this._rowCount; ++i)
		{
			this._cells.push([]);
			for (var j = 0; j < this._columnCount; ++j)
				this._cells[i].push(null);
		}

		if (!fixed)
		{
			this._scroll = new ScrollViewTouchWrapper(2); // click depth for inner container of table view

			this._scroll.setDirection(ccui.ScrollView.DIR_VERTICAL);
			this._scroll.setBounceEnabled(true);
			this._scroll.setInertiaScrollEnabled(true);
			this._scroll.setScrollBarEnabled(false);
			this._scroll.setLayoutType(ccui.Layout.RELATIVE);

			this._innerLayout = new ccui.Layout();
			this._scroll.addChild(this._innerLayout);

			var align = new ccui.RelativeLayoutParameter();
			align.setAlign(ccui.RelativeLayoutParameter.PARENT_TOP_LEFT);

			this._innerLayout.setLayoutParameter(align);

			this.addChild(this._scroll);
		}

		this._updateSize();
	},

	setContentSize: function(contentSize, height)
	{
		ccui.Layout.prototype.setContentSize.call(this, contentSize, height);

		if(!this._fixed)
			this._scroll.setContentSize(contentSize, height);
	},

	_updateCells: function ()
	{
		for (var i = 0; i < this._rowCount; ++i)
		{
			for (var j = 0; j < this._columnCount; ++j)
			{
				if (this._cells[i][j])
				{
					if (this._fitObjectSizes)
					{
						var scaleX = this._cellSize.width / this._cells[i][j].getContentSize().width;
						var scaleY = this._cellSize.height / this._cells[i][j].getContentSize().height;

						this._cells[i][j].setScale(scaleX < scaleY ? scaleX : scaleY);
					}
					else if (this._cells[i][j].getScale() !== 1)
					{
						this._cells[i][j].setScale(1);
					}

					this._cells[i][j].setPosition(this._cellPosition(i, j));
				}
			}
		}
	},

	_updateSize: function ()
	{
		var height = this._cellSize.height * this._rowCount + this._cellSeparator.height * (this._rowCount + 1);
		var width = this._cellSize.width * this._columnCount + this._cellSeparator.width * (this._columnCount + 1);

		for (var i = 0; i < this._placeholderRows.length; ++i)
			height -= this._cellSize.height - this._placeholderRows[i].height;

		if (!this._fixed)
		{
			this._scroll.setContentSize(this.getContentSize());
			this._scroll.setInnerContainerSize(cc.size(width, height));
			this._innerLayout.setContentSize(width, height);

			this._scroll.getInnerContainer().forceDoLayout();
		}
		else
		{
			this.setContentSize(width, height);
		}
	},

	_cellPosition: function (i, j)
	{
		var height = this._fixed ? this.height : this._innerLayout.height;

		var placeholderSumHeight = 0;

		for (var t = 0; t < this._placeholderRows.length; ++t)
		{
			if (this._placeholderRows[t].row <= i)
				placeholderSumHeight += this._cellSize.height - this._placeholderRows[t].height;
		}

		height += placeholderSumHeight;

		return cc.p(this._cellSize.width * j + this._cellSeparator.width * (j + 1),
			height - (this._cellSize.height * i + this._cellSeparator.height * (i + 1)));
	},

	/**
     * Sets fit of object size to cell size. <br/>
     * Object will be scaled. Original scale of object will be ignored.
     * @param {Boolean} flag
     */
	setFitObjectsSizes: function (flag)
	{
		this._fitObjectSizes = flag;

		this._updateSize();
		this._updateCells();
	},

	_getCellContainer: function ()
	{
		return this._fixed ? this : this._innerLayout;
	},

	/**
     * Sets object of cell. If object is null old object will be removed.<br/>
     * Returns true if row and col in table range.
     * @param {Number} row
     * @param {Number} col
     * @param {?cc.Node} node
     * @returns {Boolean}
     */
	setCell: function (row, col, widget)
	{
		if (row < 0 || row >= this._rowCount ||
             col < 0 || col >= this._columnCount )
			return false;

		for (var i = 0; i < this._placeholderRows.length; ++i)
		{
			if (this._placeholderRows[i].row === row)
				return false;
		}

		if (this._cells[row][col])
			this._getCellContainer().removeChild(this._cells[row][col]);

		this._cells[row][col] = widget;

		if (widget)
		{
			widget.setAnchorPoint(cc.p(0, 1) );
			widget.setPosition(this._cellPosition(row, col));

			if (this._fitObjectSizes)
			{
				var scaleX = this._cellSize.width / widget.getContentSize().width;
				var scaleY = this._cellSize.height / widget.getContentSize().height;

				widget.setScale(scaleX < scaleY ? scaleX : scaleY);
			}

			this._getCellContainer().addChild(widget);
		}

		return true;
	},

	/**
	 * Returns object by row and col. Returns null if not in range.
	 * @param {Number} row
	 * @param {Number} col
	 * @returns {cc.Point}
	 */
	getCellPosition: function (row, col)
	{
		return this._cellPosition(row, col);
	},

	/**
     * Returns object by row and col. Returns null if not in range.
     * @param {Number} row
     * @param {Number} col
     * @returns {?cc.Node}
     */
	getCell: function (row, col)
	{
		if (arguments.length === 1 && cc.isObject(row))
		{
			col = row.y;
			row = row.x;
		}

		if ( row < 0 || row >= this._rowCount ||
            col < 0 || col >= this._columnCount )
			return null;

		return this._cells[row][col];
	},

	/**
     * Sets cell size.
     * @param {cc.Size} cellSize
     */
	setCellSize: function (cellSize)
	{
		this._cellSize = cellSize;

		this._updateSize();
		this._updateCells();
	},

	/**
     * Sets cell separator dimensions.
     * @param {cc.Size} cellSeparator
     */
	setCellSeparator: function (cellSeparator)
	{
		this._cellSeparator = cellSeparator;

		this._updateSize();
		this._updateCells();
	},

	/**
     * Returns cell size.
     * @returns {cc.Size}
     */
	getCellSize: function ()
	{
		return this._cellSize;
	},

	/**
     * Reruns cell separator dimensions.
     * @returns {cc.Size}
     */
	getCellSeparator: function ()
	{
		return this._cellSeparator;
	},

	/**
     * Inserts row. If values presented they will be inserted.
     * @param {Number} row
     * @param {...cc.Node|Array<cc.Node>} [values]
     */
	insertRow: function (row, values)
	{
		if(row > this._rowCount)
			return;

		var rowArray = [];
		var insertLength = 0;

		if(arguments.length === 2 && cc.isArray(arguments[1]))
		{
			insertLength = Math.min(this._columnCount, arguments[1].length);
			for (var i = 0; i < insertLength; ++i )
				rowArray.push(arguments[1][i]);
		}
		else
		{
			insertLength = Math.min(this._columnCount, arguments.length - 1);

			for (var i = 1; i < arguments.length; ++i )
				rowArray.push(arguments[i]);
		}

		for (var j = insertLength; j < this._columnCount; ++j )
			rowArray.push(null);

		this._cells.splice(row, 0, rowArray);

		this._rowCount++;

		this._updateSize();
		this._updateCells();
	},

	insertPlaceholderRow: function (row, height)
	{
		if(row > this._rowCount)
			return;

		var rowArray = [];

		for (var j = 0; j < this._columnCount; ++j )
			rowArray.push(null);

		this._cells.splice(row, 0, rowArray);

		this._placeholderRows.push(new RowPlaceholderCell(row + 1, height));

		this._placeholderRows.sort(function(a, b)
		{
			return a.row - b.row;
		});

		this._rowCount++;

		this._updateSize();
		this._updateCells();
	},

	setPlaceholderRow: function (row, height)
	{
		if(row > this._rowCount)
			return;

		for (var i = 0; i < this._placeholderRows.length; ++i)
		{
			if (this._placeholderRows[i].row === row)
				return;
		}

		for (var j = 0; j < this._columnCount; ++j )
		{
			if(this._cells[row][j])
			{
				this._getCellContainer().removeChild(this._cells[row][j]);
				this._cells[row][j] = null;
			}
		}

		this._placeholderRows.push(new RowPlaceholderCell(row, height));

		this._placeholderRows.sort(function(a, b)
		{
			return a.row - b.row;
		});

		this._updateSize();
		this._updateCells();
	},

	/**
     * Deletes row.
     * @param {Number} row
     */
	deleteRow: function (row)
	{
		if(row >= this._rowCount)
			return;

		var deleteIndex = -1;

		for (var i = 0; i < this._placeholderRows.length; ++i)
		{
			if (this._placeholderRows[i].row === row)
			{
				deleteIndex = row;
				break;
			}
		}

		if (deleteIndex !== -1)
			this._placeholderRows.splice(deleteIndex, 1);

		for (var j = 0; j < this._columnCount; ++j )
		{
			if (this._cells[row][j])
				this._getCellContainer().removeChild(this._cells[row][j]);
		}

		this._cells[row].length = 0;
		this._cells.splice(row, 1);

		this._rowCount--;

		this._updateSize();
		this._updateCells();
	},

	/**
     * Sets row count. New rows will be empty.
     * @param {Number} rowCount
     */
	setRowCount: function (rowCount)
	{
		if (this._rowCount > rowCount)
		{
			for (var i = rowCount; i < this._rowCount; ++i)
			{
				for (var j = 0; j < this._columnCount; ++j )
				{
					if (this._cells[i][j])
						this._getCellContainer().removeChild(this._cells[i][j]);
				}

				this._cells[i].length = 0;

				var deleteIndex = -1;

				for (var t = 0; t < this._placeholderRows.length; ++t)
				{
					if (this._placeholderRows[t].row === i)
					{
						deleteIndex = i;
						break;
					}
				}

				if (deleteIndex !== -1)
					this._placeholderRows.splice(deleteIndex, 1);
			}

			this._cells.splice(rowCount, this._rowCount - rowCount);
		}
		else
		{
			for (var i = this._rowCount; i < rowCount; ++i)
			{
				this._cells.push([]);

				for (var j = 0; j < this._columnCount; ++j )
					this._cells[i].push(null);
			}
		}

		this._rowCount = rowCount;

		this._updateSize();
		this._updateCells();
	},

	/**
     * Sets column count. New columns will be empty.
     * @param {Number} columnCount
     */
	setColumnCount: function (columnCount)
	{
		if (this._columnCount > columnCount)
		{
			for (var i = 0; i < this._rowCount; ++i)
			{
				for (var j = columnCount; j < this._columnCount; ++j )
				{
					if (this._cells[i][j])
						this._getCellContainer().removeChild(this._cells[i][j]);
				}

				this._cells[i].splice(columnCount, this._columnCount - columnCount);
			}
		}
		else
		{
			for (var i = 0; i < this._rowCount; ++i)
			{
				for (var j = this._columnCount; j < columnCount; ++j )
					this._cells[i].push(null);
			}
		}

		this._columnCount = columnCount;

		this._updateSize();
		this._updateCells();
	},

	/**
     * Returns row count.
     */
	getRowCount: function()
	{
		return this._rowCount;
	},

	/**
     * Returns column count.
     */
	getColumnCount: function()
	{
		return this._columnCount;
	},

	/**
     * Inserts column. If values presented they will be inserted.
     * @param {Number} col
     * @param {...cc.Node|Array<cc.Node>} [values]
     */
	insertColumn: function (col, values)
	{
		if(col > this._columnCount)
			return;

		var insertLength = 0;

		if(arguments.length === 2 && cc.isArray(arguments[1]))
		{
			insertLength = Math.min(this._rowCount, arguments[1].length);
			for (var i = 0; i < insertLength; ++i )
				this._cells[i].splice(col, 0, arguments[1][i]);
		}
		else
		{
			insertLength = Math.min(this._rowCount, arguments.length - 1);

			for (var i = 1; i < arguments.length; ++i )
				this._cells[i].splice(col, 0, arguments[i]);
		}

		for (var i = insertLength; i < this._rowCount; ++i)
			this._cells[i].splice(col, 0, null);

		this._columnCount++;

		this._updateSize();
		this._updateCells();
	},

	/**
     * Deletes column by index.
     * @param {Number} col
     */
	deleteColumn: function (col)
	{
		for (var i = 0; i < this._rowCount; ++i)
		{
			if (this._cells[i][col])
				this._getCellContainer().removeChild(this._cells[i][col]);

			this._cells[i].splice(col, 1);
		}

		this._columnCount--;

		this._updateSize();
		this._updateCells();
	},

	/**
     * Returns cell indexes by table view coordinates.
     * @param {cc.Point} pos
     * @returns {?cc.Point}
     */
	cellAtPosition: function (pos)
	{
		var posY = pos.y;
		var height = this._cellSize.height * this._rowCount + this._cellSeparator.height * (this._rowCount + 1);

		var heightBelowPos = this._cellSeparator.height;
		var prevPlaceholderRow = this._rowCount;

		var abovePlaceholderIndex = -1;

		for (var t = this._placeholderRows.length-1; t >=0; --t)
		{
			var rowDiff = prevPlaceholderRow - this._placeholderRows[t].row - 1;
			heightBelowPos += rowDiff * (this._cellSize.height + this._cellSeparator.height);

			if (heightBelowPos >= pos.y)
			{
				abovePlaceholderIndex = t;
				break;
			}

			heightBelowPos += this._placeholderRows[t].height + this._cellSeparator.height;
			prevPlaceholderRow = this._placeholderRows[t].row;
		}

		for (var i = 0; i < this._placeholderRows.length; ++i)
		{
			if (i > abovePlaceholderIndex)
				posY += this._cellSize.height - this._placeholderRows[i].height;
		}

		var x = Math.floor(pos.x / (this._cellSize.width + this._cellSeparator.width));
		var y = Math.floor((height - posY) / (this._cellSize.height + this._cellSeparator.height));

		if (x < 0 || x >= this._columnCount ||
            y < 0 || y >= this._rowCount )
			return null;
		else
			return cc.p(y, x);
	},

	/**
     * Returns cell indexes by touch.
     * @param {cc.Touch} touch
     * @returns {?cc.Point}
     */
	cellAtTouch: function (touch)
	{
		var pos = this._getCellContainer().convertTouchToNodeSpace(touch);

		return this.cellAtPosition(pos);
	}
});

var RowPlaceholderCell = cc.Class.extend({

	height: 0,
	row: -1,

	ctor: function(row, height)
	{
		this.height = height;
		this.row = row;
	}
});
