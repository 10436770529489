/**
 * Created by Vika O on 01.11.2024.
 */
/**
 * This namespace contains functions to work with user cache database.</br>
 * It contains score and user programs for loaded world.
 * @namespace
 */
dd.userCache = {
	/**
     * Sync interval in seconds
     * @type {Number}
     * @default
     */
	CACHE_SYNC_INTERVAL: 3,

	/**
     * A storage for cache. Do not change by yourself.</br>
     * Format : _records[level_id][level_stage] -> {Number}.
     * @private
     * @default
     */
	_cache: {},

	_cacheUpdated: false,
};
