/**
 * Created by Vika O on 01.11.2024.
 */

dd.getFileList = dd.getFileList || function()
{};

dd.convertIPAddress = dd.convertIPAddress || function()
{};

dd.setHttpRequestTimeout = dd.setHttpRequestTimeout || function()
{};

dd.setParallaxArrayItemOffset = dd.setParallaxArrayItemOffset || function(parallax, index, offset)
{
	parallax.getParallaxArray()[index].setOffset(offset);
};

dd.setParallaxArrayItemRatio = dd.setParallaxArrayItemRatio || function(parallax, index, ratio)
{
	parallax.getParallaxArray()[index].setRatio(ratio);
};

dd.getParallaxArrayItemChild = dd.getParallaxArrayItemChild || function(parallax, index)
{
	return parallax.getParallaxArray()[index].getChild();
};

dd.scrollViewOnTouchBegan = dd.scrollViewOnTouchBegan || function(scrollView, touch, event)
{
	return scrollView.onTouchBegan(touch, event);
};

dd.scrollViewOnTouchMoved = dd.scrollViewOnTouchMoved || function(scrollView, touch, event)
{
	return scrollView.onTouchMoved(touch, event);
};

dd.scrollViewOnTouchEnded = dd.scrollViewOnTouchEnded || function(scrollView, touch, event)
{
	return scrollView.onTouchEnded(touch, event);
};

dd.scrollViewOnTouchCancelled = dd.scrollViewOnTouchCancelled || function(scrollView, touch, event)
{
	return scrollView.onTouchCancelled(touch, event);
};

dd.playAudio = dd.playAudio || function(file, loop, volume) {};
dd.pauseAudio = dd.pauseAudio || function(audioID) {};
dd.resumeAudio = dd.resumeAudio || function(audioID) {};
dd.stopAudio = dd.stopAudio || function(audioID) {};
dd.setAudioVolume = dd.setAudioVolume || function(audioID, volume) {};
dd.getAudioVolume = dd.getAudioVolume || function(audioID) {};
dd.getAudioState = dd.getAudioState || function(audioID) {};

dd.socialLogin = dd.socialLogin || function(authUrl, callback) {};

dd.createNativeTouch = dd.createNativeTouch || function(id, x, y) {};
dd.setNativeTouchLocation = dd.setNativeTouchLocation || function(x, y) {};
dd.destroyNativeTouch = dd.destroyNativeTouch || function() {};
dd.dispatchEventFromNativeTouch = dd.dispatchEventFromNativeTouch || function(eventCode) {};

dd.btleManager = dd.BTLEManager ? dd.BTLEManager.getInstance() : {
	isBTLEAvailable: function()
	{
		return false;
	},

	setReceiveDataCallback: function(callback) {},
	setConnectCallback: function(callback) {},
	setDisconnectCallback: function(callback) {},
	scan: function() {},
	cleanup: function() {},
	sendData: function(data) {}
};

dd.broadcastServer = dd.BroadcastServer ? dd.BroadcastServer.getInstance() : {};
dd.broadcastClient = dd.BroadcastClient ? dd.BroadcastClient.getInstance() : {};

dd.broadcastServer.DATA_PORT = 18842;

dd.BROADCAST_PACKET_PRIORITY ={
	LOW: 1,
	HIGH: 0
};

dd.BROADCAST_PACKET_TYPE = {
	DISCOVER_REQUEST: 0,
	DISCOVER_INFO: 1,
	DATA_REQUEST: 2
};

dd.GameServer = dd.GameServer || cc.Class.extend({});

dd.GameServer.CLIENT_CONNECTED_EVENT = 0;
dd.GameServer.CLIENT_DISCONNECTED_EVENT = 1;

dd.MagnetClient = dd.MagnetClient || cc.Class.extend({});

dd.GAME_SERVER_PORT = 18843;
dd.TEACHER_SERVER_PORT = 18844;

dd.GameClient = dd.GameClient || cc.Class.extend({});

dd.GameClient.CONNECTED_EVENT = 0;
dd.GameClient.DISCONNECTED_EVENT = 1;

//dd.marketHelper = dd.MarketHelper ? dd.MarketHelper.getInstance() : {
dd.marketHelper = {
	setProductInfoCallback: function(callback) {},
	requestProductInfo: function(products) {},
	setPaymentEventCallback: function(callback) {},
	purchaseProduct: function(product) {},
	restorePurchases: function() {},
	cleanup: function() {},
	cleanupTransaction: function() {},
	isMarketAvailable: function() { return false; },
	getDeviceUUID: function() { return "" }
};

dd.marketHelper.EVENT = {
	PURCHASE: 0,
	RESTORE: 1
};

dd.TextFieldWrapper = dd.TextFieldWrapper || ccui.TextField;
