/**
 * Created by Nikita Besshaposhnikov on 01.12.14.
 */

/**
 * This callback is used by checkbox on click.
 * @callback dui.CheckBox~clickCallback
 * @param {Boolean} state State of checkbox.
 */

/**
 * @class dui.CheckBox with label.
 * @extends ccui.Layout
 * @constructor
 * @param {String} text Label text.
 * @param {dui.CheckBox~clickCallback} clickCallback Click callback.
 * @param {cc.Node} target Target for callback.
 * @param {Number} [fontSize] Size of font used by checkbox label.
 * @param {Boolean} [state=false] Start state of checkbox.
 */
dui.CheckBox = ccui.Layout.extend(/** @lends dui.CheckBox# */{
	_clickCallback: null,
	_target: null,
	_checkBox: null,
	_label: null,

	ctor: function(text, clickCallback, target, fontSize, state)
	{
		this._super();
		this.setCascadeOpacityEnabled(true);

		if(state === undefined)
			state = false;

		if(fontSize === undefined)
			fontSize = dd.settings.fontDefinition.fontSize;

		this._clickCallback = clickCallback;
		this._target = target;

		this._checkBox = new ccui.CheckBox(
			dd.spriteUtils.getInterfaceElementName("unchecked"),
			dd.spriteUtils.getInterfaceElementName("unchecked-selected"),
			dd.spriteUtils.getInterfaceElementName("checked"),
			dd.spriteUtils.getInterfaceElementName("unchecked-disabled"),
			dd.spriteUtils.getInterfaceElementName("checked-disabled"),
			ccui.Widget.PLIST_TEXTURE
		);

		this._checkBox.setSelected(state);
		this._checkBox.setAnchorPoint(cc.p(0, 0));
		this._checkBox.addEventListener(this._checkBoxClicked, this);

		this.setTouchEnabled(true);
		this.addClickEventListener(this._clicked.bind(this));

		this.addChild(this._checkBox);

		this._label = new ccui.Text(text, dd.settings.fontName, fontSize);
		this._label.setPosition(cc.p(this._checkBox.width + 5, 0));
		this._label.setAnchorPoint(cc.p(0, 0));

		this.addChild(this._label);

		var height = Math.max(this._label.height, this._checkBox.height);

		this.setContentSize(this._checkBox.width + 5 + this._label.width, height);

	},

	getChecked: function()
	{
		return this._checkBox.isSelected();
	},

	setChecked: function(state)
	{
		this._checkBox.setSelected(state);
	},

	setEnabled: function(flag)
	{
		this._checkBox.setEnabled(flag);

		if(flag)
			this._label.setColor(cc.color(255, 255, 255));
		else
			this._label.setColor(cc.color(174, 174, 174));

		ccui.Layout.prototype.setEnabled.call(this, flag);
	},

	_checkBoxClicked: function(target, event)
	{
		if(this._clickCallback)
			this._clickCallback.call(this._target, event === ccui.CheckBox.EVENT_SELECTED);
	},

	_clicked: function()
	{
		var value = this._checkBox.isSelected();
		this._checkBox.setSelected(!value);

		if(this._clickCallback)
			this._clickCallback.call(this._target, !value);
	}
});
