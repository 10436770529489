/**
 * Created by Nikita Besshaposhnikov on 28.07.15.
 */

/**
 * This callback is used when dropped item.
 * @callback dui.DragAndDropLayout~event
 * @param {ccui.Widget} element Element which was dragged.
 * @param {dui.DragAndDropLayout.Event} eventType Event type.
 * @param {cc.Touch} touch Location of event.
 */

/**
 * @class Interface for layout with drag and drop actions.
 * @interface
 * @extends ccui.Layout
 * @property {Boolean} clickEnabled
 * @property {Boolean} dragEnabled
 * @property {Boolean} dropEnabled
 * @constructor
 * @param {dui.DragAndDropLayout.Type} type
 * @param {String} [dndName] Name of drag and drop group. <br/>
 * If type is {@link dui.DragAndDropLayout.Type.DRAG_ONLY} not needed.
 */
dui.DragAndDropLayout = ccui.Layout.extend(/** @lends dui.DragAndDropLayout# */{
	_type: 0,
	_dndName: "",
	_dropTargets: [],

	_callback: null,
	_callbackTarget: null,

	_draggedElement: null,
	_clonedElement: null,
	_startTouchPoint: cc.p(0, 0),
	_dragStarted: false,

	_touchIntercepted: false,
	clickEnabled: true,
	dragEnabled: true,
	dropEnabled: true,
	ignoreBoundingBox: false,

	ctor: function(type, dndName)
	{
		this._super();

		this._type = type;

		if(type !== dui.DragAndDropLayout.Type.DRAG_ONLY)
		{
			this._dndName = dndName;

			if(!dui.DragAndDropLayout._dndLayoutsMap[dndName])
				dui.DragAndDropLayout._dndLayoutsMap[dndName] = [];

			dui.DragAndDropLayout._dndLayoutsMap[dndName].push(this);
		}

		if(type === dui.DragAndDropLayout.Type.DRAG_AND_DROP)
			this._dropTargets.push(dndName);

		var touchListener = cc.EventListener.create({
			event: cc.EventListener.TOUCH_ONE_BY_ONE,
			swallowTouches: true,
			onTouchBegan: this._touchBegan.bind(this),
			onTouchMoved: this._touchMoved.bind(this),
			onTouchEnded: this._touchEnded.bind(this)
		});

		cc.eventManager.addListener(touchListener, this);
	},

	onExit: function()
	{
		ccui.Layout.prototype.onExit.call(this);

		if(this._type !== dui.DragAndDropLayout.Type.DRAG_ONLY)
		{
			var index = dui.DragAndDropLayout._dndLayoutsMap[this._dndName].indexOf(this);
			dui.DragAndDropLayout._dndLayoutsMap[this._dndName].splice(index, 1);
		}
	},

	setBright: function (flag)
	{
		var child = this.getChildren();

		for(var i =0 ; i < this.getChildrenCount(); ++i)
			child[i].setBright(flag);
	},

	_touchBegan: function(touch, event)
	{
		if(!this.enabled)
			return false;

		if(this._dragStarted)
		{
			return;
		}

		this._touchIntercepted = this._interceptTouchBegan(touch, event);

		if(this._touchIntercepted)
			return true;

		if(this._type === dui.DragAndDropLayout.Type.DROP_ONLY)
			return false;

		var touchPoint = this.convertTouchToNodeSpace(touch);

		if(!this.ignoreBoundingBox)
		{
			if (!cc.rectContainsPoint(this.getBoundingBox(), this.getParent().convertTouchToNodeSpace(touch)) ||
				!this.isClippingParentContainsPoint(touch.getLocation()))
				return false;
		}

		var element = this._findElementByTouch(touch);

		if(!element || !element.isEnabled() || !this._isElementClickable(element))
			return false;

		//this._dragStarted = false;
		this._startTouchPoint = touchPoint;

		if(this.clickEnabled)
			element.setHighlighted(true);

		this._draggedElement = element;

		return true;
	},

	_touchMoved: function(touch, event)
	{
		if(this._touchIntercepted)
		{
			this._interceptTouchMoved(touch, event);
			return;
		}

		if(!this.dragEnabled)
			return;

		var touchPoint = this.convertTouchToNodeSpace(touch);

		if(!this._dragStarted &&
                this._isElementDraggable(this._draggedElement, touch) &&
                cc.pDistance(this._startTouchPoint, touchPoint) >= dd.TOUCH_CRITICAL_DISTANCE)
		{
			if(this.clickEnabled)
				this._draggedElement.setHighlighted(true);

			this._clonedElement = this._cloneElement(this._draggedElement, touch);

			if(!this._clonedElement)
				return;

			var draggedElementBB = this._draggedElement.getBoundingBoxToWorld();
			//this._clonedElement.setScale(draggedElementBB.width / this._clonedElement.width);

			this._dragStarted = true;

            dd.runningSceneUtils.addChild(this._clonedElement);

			var dropTarget = this._findDropTarget(touchPoint);

			if(dropTarget && dropTarget._type !== dui.DragAndDropLayout.Type.DRAG_ONLY)
			{
				dropTarget._callback.call(dropTarget._callbackTarget,
					this._draggedElement, dui.DragAndDropLayout.Event.DRAG_START, touch, this._clonedElement);
			}
		}

		if(this._dragStarted)
		{
			var clonedPosition = this.convertToWorldSpace(touchPoint);

			this._clonedElement.setPosition(clonedPosition);
			var dropTarget = this._findDropTarget(touchPoint);

			if(dropTarget && dropTarget._type !== dui.DragAndDropLayout.Type.DRAG_ONLY)
			{
				dropTarget._callback.call(dropTarget._callbackTarget,
					this._draggedElement, dui.DragAndDropLayout.Event.DRAG_START, touch, this._clonedElement);
			}
		}
	},

	_touchEnded: function(touch, event)
	{
		if(this._touchIntercepted)
		{
			this._interceptTouchEnded(touch, event);
			return;
		}

		var touchPoint = this.convertTouchToNodeSpace(touch);

		this._draggedElement.setHighlighted(false);

		if(this._dragStarted)
		{
			this._dragStarted = false;
			var cancel = false;
			var dropTarget = this._findDropTarget(touchPoint);

			if(!dropTarget || dropTarget._type === dui.DragAndDropLayout.Type.DRAG_ONLY)
				cancel = true;

			if(!cancel && dropTarget && !dropTarget._findElementByTouch(touch, this._clonedElement))
				cancel = true;

			if(cancel)
			{
				if(this._callback)
				{
					this._callback.call(this._callbackTarget, this._draggedElement,
						dui.DragAndDropLayout.Event.DRAG_CANCELLED, touch);
				}

				var scaleAction = cc.scaleTo(dd.SYSTEM_ANIMATION_DELAY, 0.01);
				var selfRemove = cc.callFunc(function() { dd.runningSceneUtils.removeChild(this) }, this._clonedElement);

				this._clonedElement.runAction(cc.sequence(scaleAction, selfRemove));

				return;
			}

			if(dropTarget.dropEnabled && dropTarget._callback)
			{
				dropTarget._callback.call(dropTarget._callbackTarget, this._draggedElement,
					dui.DragAndDropLayout.Event.DROP, touch, this._clonedElement);

				dd.runningSceneUtils.removeChild(this._clonedElement);
			}

			this._clonedElement = null;
		}
		else if(this.clickEnabled && this._callback)
		{
			this._callback.call(this._callbackTarget, this._draggedElement,
				dui.DragAndDropLayout.Event.CLICKED, touch);
		}

	},

	_findDropTarget: function(touchPoint)
	{
		var worldPoint = this.convertToWorldSpace(touchPoint);

		for(var i = 0; i < this._dropTargets.length; ++i)
		{
			var proposedWidgets = dui.DragAndDropLayout._dndLayoutsMap[this._dropTargets[i]];

			if(!proposedWidgets)
				continue;

			for(var j =0 ;j < proposedWidgets.length; ++j)
			{
				var nodePoint = proposedWidgets[j].getParent().convertToNodeSpace(worldPoint);

				if(cc.rectContainsPoint(proposedWidgets[j].getBoundingBox(), nodePoint))
					return proposedWidgets[j];
			}
		}

		return null;
	},

	/**
     * Add drop target group name. </br>
     * If type of layer is dui.DragAndDropLayout.Type.DROP_ONLY do nothing.
     * @param {String} name Drop target group name.
     */
	addDropTargetName: function(name)
	{
		if(this._type === dui.DragAndDropLayout.Type.DROP_ONLY)
			return;

		this._dropTargets.push(name);
	},

	_findElementByTouch: function(worldPoint, object) { return null; },

	/**
     * Returns true if element is draggable
     * @protected
     * @param {ccui.Widget} element
	 * * @param {ccui.Point} touch
     * @returns {Boolean}
     */
	_isElementDraggable: function(element, touch) { return true; },

	/**
     * Returns true if element is clickable
     * @protected
     * @param {ccui.Widget} element
     * @returns {Boolean}
     */
	_isElementClickable: function(element) { return true; },

	/**
     * Clones element when drag start. Need to override.
     * @protected
     * @param {ccui.Widget} element
     * @returns {ccui.Widget}
     */
	_cloneElement: function(element, touch) { return null; },

	/**
     * Intercepts touch began. Returns true if intercepted
     * @protected
     * @param {cc.Touch} touch
     * @param {cc.Event} event
     * @returns {Boolean}
     */
	_interceptTouchBegan: function(touch, event) { return false; },

	/**
     * Intercepts touch moved.
     * @protected
     * @param {cc.Touch} touch
     * @param {cc.Event} event
     * @returns {Boolean}
     */
	_interceptTouchMoved: function(touch, event) {},

	/**
     * Intercepts touch ended.
     * @protected
     * @param {cc.Touch} touch
     * @param {cc.Event} event
     * @returns {Boolean}
     */
	_interceptTouchEnded: function(touch, event) {},

	/**
     * @param {dui.DragAndDropLayout~event} callback
     * @param {ccui.Widget} target
     */
	addDNDEventListener: function(callback, target)
	{
		this._callback = callback;
		this._callbackTarget = target;
	}
});

dui.DragAndDropLayout._dndLayoutsMap = {};

/**
 * Types of Drag-and-Drop layout
 * @enum {Number}
 */
dui.DragAndDropLayout.Type = {
	DRAG_ONLY: 0,
	DROP_ONLY: 1,
	DRAG_AND_DROP: 2,
	CANCELLED: 3
};

/**
 * Events of Drag-and-Drop layout
 * @enum {Number}
 */
dui.DragAndDropLayout.Event = {
	DRAG_START: 0,
	DROP: 1,
	CLICKED: 2,
	DRAG_CANCELLED: 3
};
