let LoadLayer = cc.Layer.extend(/** @extends LoadLayer# */{

    ctor: function()
    {
        this._super();

        let colorLayer = new cc.LayerColor(cc.color(0, 163, 94), dd.settings.getScreenSize().width, dd.settings.getScreenSize().height);
        colorLayer.setAnchorPoint( cc.p() );
        colorLayer.setPosition( cc.p() );
        this.addChild(colorLayer, -1);

        let backGround = new cc.Sprite("Background/LoadBackground.png");

        let screenSize = dd.settings.getScreenSize();

        let maxWidth = backGround.width;
        let totalHeight = backGround.height;

        let layer = new cc.Layer();
        layer.setContentSize(maxWidth, totalHeight);
        let scale = Math.min(screenSize.width / maxWidth, screenSize.height / totalHeight);

        if(scale > 1.5)
            scale = 1.5;

        backGround.setAnchorPoint(cc.p(0.5, 0));
        backGround.setPosition(maxWidth/ 2, 0);
        layer.addChild(backGround, 1);

        layer.setPosition((screenSize.width - layer.width) / 2, (screenSize.height - layer.height ) / 2);
        layer.setScale(scale);
        this.addChild(layer);

        let loadBarLayer = new cc.ClippingNode();
        loadBarLayer.setContentSize(647, 127);
        loadBarLayer.setAnchorPoint(cc.p(0, 0));

        let shape = new cc.DrawNode();
        let rect = [
            cc.p(0, 0), cc.p(0, loadBarLayer.height),
            cc.p(loadBarLayer.width, loadBarLayer.height), cc.p(loadBarLayer.width, 0)
        ];

        let color = cc.color(0, 255, 0, 255);
        shape.drawPoly(rect, color, 0, color);

        if (dd.settings.isRetinaEnabled)
            cc.loader.loadAliases("res/resources-hd.plist", this._endLoadAliases.bind(this));
        else
            cc.loader.loadAliases("res/resources.plist", this._endLoadAliases.bind(this));
    },

    _endLoadAliases: function()
    {
        cc.director.runScene(new StartMenuScene());
    }
});

/**
 * @class This scene showing on start of PiktoMir, while loading world data.
 * @see LoadLayer
 * @extends cc.Scene
 */
let LoadScene = cc.Scene.extend(/** @lends LoadScene# */{

    ctor: function () {
        this._super();
    },

    onEnter: function () {
        let layer = new LoadLayer();
        this.addChild(layer);
    }
});