/**
 * Created by Vika O on 01.11.2024.
 */

/**
 * This namespace contains all PM fonts and functions to work with them
 * @namespace
 */
dd.fonts = {
	"Comfortaa-Light": {type: "font", name: "Comfortaa-Light", srcs: ["res/fonts/Comfortaa-Light.ttf"]},
	"Comfortaa-Regular": {type: "font", name: "Comfortaa-Regular", srcs: ["res/fonts/Comfortaa-Regular.ttf"]},
	"Comfortaa-Bold": {type: "font", name: "Comfortaa-Bold", srcs: ["res/fonts/Comfortaa-Bold.ttf"]},
	"Evolventa": {type: "font", name: "Evolventa", srcs: ["res/fonts/Evolventa-Regular.ttf"]},

	/**
     * Gets real font name to load with cocos2d-x
     * @param {String} fontName
     * @returns {String}
     */
	getRealFontName: function(fontName)
	{
		if(!this[fontName])
			return "Arial";

		if(cc.sys.isNative)
			return this[fontName].srcs[0];
		else
			return this[fontName].name;
	}
};
