/**
 * Created by Vika O on 01.11.2024.
 */

/**
 * @const
 * @default
 * @type {string}
 */
var dVersion = "0.0.0";
/**
 * @const
 * @default
 * @type {string}
 */
var build = "debug";
/**
 * @const
 * @default
 * @type {Number}
 */
var DURAK_SUPPORTED_LEVEL_FORMAT_VERSION = 1;