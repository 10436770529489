/**
 * Created by Nikita Besshaposhnikov on 27.09.16.
 */

/**
 * Wrapper for cocos Layout.
 * @class
 * @extends ccui.Layout
 */
dui.Layout = ccui.Layout.extend(/** @lends dui.Layout# */{

	_locked: false,
	_originalState: true,

	ctor: function()
	{
		this._super();
	},

	setEnabled: function(flag)
	{
		if(this._locked)
			return;

		ccui.Layout.prototype.setEnabled.call(this, flag);
	},

	/**
     * Locks layout enabled state. Layout don't changes it's state until 'unlock' is called
     */
	lock: function()
	{
		this._locked = true;

		this._originalState = this.enabled;

		ccui.Layout.prototype.setEnabled.call(this, false);
	},

	/**
     * Unlocks layout enabled state && restores original state
     */
	unlock: function()
	{
		this._locked = false;

		ccui.Layout.prototype.setEnabled.call(this, this._originalState);
	}
});
