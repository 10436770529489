/**
 * This property is used to store constructors for all "data-classes".
 * Do not change anything in this namespace.
 */
dd.classMapping = {};
/**
 * This function loads "data-classes" constructors from data namespace
 * @see data
 */
dd.loadClassMapping = function()
{
	for(var key in dd.data)
		dd.classMapping[dd.data[key].prototype.typeName] = dd.data[key];
};
