dd.StackLength = 36;
dd.Trump = {
    rank: '',
    suit: ''
};

// dui.CardWidth = (new cc.Sprite("Cards/CardSprite.png")).width;
dui.CardWidth = dd.settings.isRetinaEnabled ? 248 : 124;//(new cc.Sprite("Cards/CardSprite.png")).width;

dui.Card = ccui.Layout.extend(/** @lends dui.Card# */{

    rank: '',
    suit: '',
    greaterCard: null,

    ctor: function(rank, suit)
    {
        this._super();

        if (suit !== undefined)
        {
            this.rank = rank;
            this.suit = suit;
        }
        else
        {
            this.rank = rank[0];
            this.suit = rank[1];
        }

        this.setLayoutType(ccui.Layout.RELATIVE);

        let cardSprite = new cc.Sprite("Cards/CardSprite.png");
        cardSprite.setAnchorPoint(cc.p(0,0));
        cardSprite.setPosition(cc.p(0,0));

        this.addChild(cardSprite, -10);
        this.setContentSize(cardSprite.width, cardSprite.height);

        let upperLayout = this._drawRankAndSuit();

        let upperAlign = new ccui.RelativeLayoutParameter();
        upperAlign.setAlign(ccui.RelativeLayoutParameter.PARENT_TOP_LEFT);
        upperAlign.setMargin(dui.Card.BORDER_SEPARATOR, dui.Card.BORDER_SEPARATOR, 0, 0);
        upperLayout.setLayoutParameter(upperAlign);

        this.addChild(upperLayout);

        let lowerLayout = this._drawRankAndSuit();
        lowerLayout.setRotation(180);

        let lowerAlign = new ccui.RelativeLayoutParameter();
        lowerAlign.setAlign(ccui.RelativeLayoutParameter.PARENT_RIGHT_BOTTOM);
        lowerAlign.setMargin(0, 0, dui.Card.BORDER_SEPARATOR, dui.Card.BORDER_SEPARATOR);
        lowerLayout.setLayoutParameter(lowerAlign);

        this.addChild(lowerLayout);

        let suitSpriteCenter = new cc.Sprite(dd.spriteUtils.getCardSuitSprite(this.suit));
        suitSpriteCenter.setAnchorPoint(cc.p(0.5,0.5));
        suitSpriteCenter.setPosition(cc.p(cardSprite.width / 2, cardSprite.height / 2 + 5));

        this.addChild(suitSpriteCenter);
    },

    _drawRankAndSuit: function ()
    {
        let layout = new ccui.Layout();
        layout.setAnchorPoint(cc.p(0.5,0.5));

        let scale = 0.25;

        let suitSprite = new cc.Sprite(dd.spriteUtils.getCardSuitSprite(this.suit));
        suitSprite.setScale(scale);
        suitSprite.setAnchorPoint(cc.p(0.5,0));
        layout.addChild(suitSprite);

        let rankText = new ccui.Text(dd.RANK_TEXTS[this.rank], dd.settings.fontName, dd.settings.fontSize);
        rankText.setColor(new cc.Color(0, 100, 0, 255));
        rankText.setAnchorPoint(cc.p(0.5, 0));

        layout.addChild(rankText);

        let width = Math.max(suitSprite.width * scale, rankText.width);

        suitSprite.setPosition(cc.p(width / 2,0));
        rankText.setPosition(cc.p(width / 2, suitSprite.height * scale + 1));

        layout.setContentSize(width, suitSprite.height * scale + rankText.height + 5);

        return layout;
    },

    drawShadow: function ()
    {
        if (this.suit === dd.Trump.suit)
        {
            let trumpShadow = new cc.Sprite("Cards/TrumpShadow.png");
            trumpShadow.setAnchorPoint(cc.p(0.5,0.5));
            trumpShadow.setPosition(cc.p(this.width / 2, this.height / 2));

            this.addChild(trumpShadow, -9, dui.Card.TRUMP_SHADOW_TAG);
        }
    },

    removeShadow: function ()
    {
        if (this.suit === dd.Trump.suit)
            this.removeChildByTag(dui.Card.TRUMP_SHADOW_TAG);
    },

    getString: function ()
    {
        return this.rank + this.suit;
    },

    addGreaterCard: function(card)
    {
        this.greaterCard = card;

        let cardAlign = new ccui.RelativeLayoutParameter();
        cardAlign.setAlign(ccui.RelativeLayoutParameter.PARENT_LEFT_BOTTOM);
        cardAlign.setMargin(20, 0, 0, 15);
        card.setLayoutParameter(cardAlign);

        this.addChild(card);
    },
    
    isGreater: function (card)
    {
        if ((dd.RANKS[card.rank] < dd.RANKS[this.rank] && card.suit === this.suit) ||
            (card.suit !== dd.Trump.suit && this.suit === dd.Trump.suit))
            return true;
        
        return false;
    },

    isSameRank: function(card)
    {
        if (this.rank === card.rank)
            return true;

        if (this.greaterCard && (this.greaterCard.rank === card.rank))
            return true;

        return false;
    }
});

dui.Card.BORDER_SEPARATOR = 6;
dui.Card.TRUMP_SHADOW_TAG = 1001001;

dd.RANKS = {
    '6': 0,
    '7': 1,
    '8': 2,
    '9': 3,
    'T': 4,
    'J': 5,
    'Q': 6,
    'K': 7,
    'A': 8
};

dd.RANK_TEXTS = {
    '6': '6',
    '7': '7',
    '8': '8',
    '9': '9',
    'T': '10',
    'J': 'J',
    'Q': 'Q',
    'K': 'K',
    'A': 'A'
};

dd.Suits = {
    "S": "Spades",
    "H": "Hearts",
    "D": "Diamonds",
    "C": "Clubs"
};