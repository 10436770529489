let TableLayer = ccui.Layout.extend(/** @lends TableLayer# */{

    _gameLayout: null,

    _firstRow: null,
    _secondRow: null,

    ctor: function (gameLayout)
    {
        this._super();

        this._gameLayout = gameLayout;

        this.setContentSize(0, GameLayer.CARDS_ON_TABLE_HEIGHT * 2 + GameLayer.CARDS_SEPARATOR_Y);
        this.setLayoutType(ccui.Layout.LINEAR_VERTICAL);

        this.setAnchorPoint(cc.p(0.5, 0.5));

        this._firstRow = new ccui.HBox();
        this._firstRow.setContentSize(0, GameLayer.CARDS_ON_TABLE_HEIGHT);

        let alignFirst = new ccui.LinearLayoutParameter();
        alignFirst.setGravity(ccui.LinearLayoutParameter.CENTER_HORIZONTAL);
        this._firstRow.setLayoutParameter(alignFirst);

        this.addChild(this._firstRow);

        this._secondRow = new ccui.HBox();
        this._secondRow.setContentSize(0, GameLayer.CARDS_ON_TABLE_HEIGHT);

        let alignSecond = new ccui.LinearLayoutParameter();
        alignSecond.setMargin(0, GameLayer.CARDS_SEPARATOR_Y, 0, 0);
        alignSecond.setGravity(ccui.LinearLayoutParameter.CENTER_HORIZONTAL);
        this._secondRow.setLayoutParameter(alignSecond);

        this.addChild(this._secondRow);
    },

    canAddCard: function (card)
    {
        if (this._secondRow.children.length === 3)
            return false;
        else if (this._firstRow.children.length === 0)
            return true;
        else
        {
            let canAdd = false;

            for (let i = 0; i < this._firstRow.children.length; ++i)
                canAdd = canAdd || this._firstRow.children[i].isSameRank(card)

            for (let i = 0; i < this._secondRow.children.length; ++i)
                canAdd = canAdd || this._secondRow.children[i].isSameRank(card)

            return canAdd;
        }
    },

    addCard: function (card)
    {
        let cardAlign = new ccui.LinearLayoutParameter();
        cardAlign.setGravity(ccui.LinearLayoutParameter.CENTER_VERTICAL);
        cardAlign.setMargin(0, 0, GameLayer.CARDS_SEPARATOR_X, 0);

        card.setLayoutParameter(cardAlign);

        if (this._firstRow.children.length < 3)
        {
            this._firstRow.addChild(card);

            this._firstRow.setContentSize(this._firstRow.width + card.width + GameLayer.CARDS_SEPARATOR_X, this._firstRow.height);

            this.setContentSize(this._firstRow.width, this.height);
        }
        else
        {
            this._secondRow.addChild(card);

            this._secondRow.setContentSize(this._secondRow.width + card.width + GameLayer.CARDS_SEPARATOR_X, this._secondRow.height);
        }

        this.forceDoLayout();
    },

    detectTransfer: function (card, touch)
    {
        if (this._firstRow.children.length === 0)
            return false;

        let isTrump = (card.suit === dd.Trump.suit);

        for (let i = 0; i < this._firstRow.children.length; ++i)
        {
            let tableCard = this._firstRow.children[i];

            if (tableCard.greaterCard || tableCard.rank !== card.rank)
                return false;

            if (isTrump && this._cardContainsTouch(tableCard, touch)) //бьем карту
                return false;
        }

        for (let i = 0; i < this._secondRow.children.length; ++i)
        {
            let tableCard = this._secondRow.children[i];

            if (tableCard.greaterCard || tableCard.rank !== card.rank)
                return false;

            if (isTrump && this._cardContainsTouch(tableCard, touch)) //бьем карту
                return false;
        }

        return true;
    },

    findBeatenCard: function (card, touch)
    {
        for (let i = 0; i < this._firstRow.children.length; i++)
        {
            let beatenCard = this._firstRow.children[i];

            if (this._cardContainsTouch(beatenCard, touch) && card.isGreater(beatenCard) && !beatenCard.greaterCard)
                return beatenCard;
        }

        for (let i = 0; i < this._secondRow.children.length; i++)
        {
            let beatenCard = this._secondRow.children[i];

            if (this._cardContainsTouch(beatenCard, touch) && card.isGreater(beatenCard) && !beatenCard.greaterCard)
                return beatenCard;
        }

        return null;
    },

    getNotBeatenCardsCount: function ()
    {
        let count = 0;

        for (let i = 0; i < this._firstRow.children.length; i++)
            if (!this._firstRow.children[i].greaterCard)
                ++count;

        for (let i = 0; i < this._secondRow.children.length; i++)
            if (!this._secondRow.children[i].greaterCard)
                ++count;

        return count;
    },

    findCardOnTable: function (rank, suit)
    {
        let cards = this._firstRow.children;
        for (let i = 0; i < cards.length; ++i)
            if (cards[i].rank === rank && cards[i].suit === suit)
                return cards[i];

        cards = this._secondRow.children;
        for (let i = 0; i < cards.length; ++i)
            if (cards[i].rank === rank && cards[i].suit === suit)
                return cards[i];

        return null;
    },

    getCardPosition: function (rank, suit)
    {
        for (let i = 0; i < this._firstRow.children.length; ++i)
        {
            let card = this._firstRow.children[i];

            if (card.rank === rank && card.suit === suit)
            {
                let transform = this._firstRow.getNodeToWorldTransform();

                let pos = card.getPosition();
                pos.x += transform.tx;
                pos.y += transform.ty;

                return pos;
            }
        }

        for (let i = 0; i < this._secondRow.children.length; ++i)
        {
            let card = this._secondRow.children[i];

            if (card.rank === rank && card.suit === suit)
            {
                let transform = this._secondRow.getNodeToWorldTransform();

                let pos = card.getPosition();
                pos.x += transform.tx;
                pos.y += transform.ty;

                return pos;
            }
        }

        return null;
    },

    getLastCardPosition: function ()
    {
        if (this._firstRow.children.length < 3)
        {
            let transform = this._firstRow.getNodeToWorldTransform();

            let pos = cc.p(0,0);

            if (this._firstRow.children.length > 0)
            {
                let lastCard = this._firstRow.children[this._firstRow.children.length - 1];

                pos = lastCard.getPosition();
                pos.x += lastCard.width;
            }

            pos.x += transform.tx;
            pos.y += transform.ty;

            return pos;
        }
        else
        {
            let transform = this._secondRow.getNodeToWorldTransform();

            let pos = cc.p(0,0);

            if (this._secondRow.children.length > 0)
            {
                let lastCard = this._secondRow.children[this._secondRow.children.length - 1];

                pos = lastCard.getPosition();
                pos.x += 10;//lastCard.width;
            }

            pos.x += transform.tx;
            pos.y += transform.ty;

            return pos;
        }
    },

    getCardAnimations: function (type, pos, callback)
    {
        let cardAnimations = [];

        let transform = this._firstRow.getNodeToWorldTransform();

        for (let i = 0; i < this._firstRow.children.length; i++)
            cardAnimations.push(this._getCardAnimation(type, pos, callback, this._firstRow.children[i], transform));

        transform = this._secondRow.getNodeToWorldTransform();

        for (let i = 0; i < this._secondRow.children.length; i++)
            cardAnimations.push(this._getCardAnimation(type, pos, callback, this._secondRow.children[i], transform));

        return cardAnimations;
    },

    _getCardAnimation: function (type, pos, callback, card, transform)
    {
        let newPos, moveCard, moveEnd;

        switch (type)
        {
            case TableLayer.AnimationTypes.Beaten:
                newPos = cc.p(pos.x - transform.tx, pos.y - card.height / 2 - transform.ty);

                return cc.targetedAction(card, cc.moveTo(dd.SYSTEM_ANIMATION_DELAY * 2, newPos));

            case TableLayer.AnimationTypes.TakeAll:
                newPos = cc.p(pos.x - card.width - transform.tx, pos.y - transform.ty);

                moveCard = cc.targetedAction(card, cc.moveTo(dd.SYSTEM_ANIMATION_DELAY * 2, newPos));

                moveEnd = cc.callFunc(callback, this, card);

                return cc.sequence(moveCard, moveEnd);
        }
    },

    clean: function ()
    {
        this._firstRow.removeAllChildren();
        this._firstRow.setContentSize(0, this._firstRow.height);

        this._secondRow.removeAllChildren();
        this._secondRow.setContentSize(0, this._secondRow.height);

        this.setContentSize(0, this.height);
    },

    _cardContainsTouch: function (card, touch)
    {
        let pos = card.convertTouchToNodeSpace(touch);

        // if (pos.x >= 0 && pos.x <= card.width &&
        //     pos.y >= 0 && pos.y <= card.height)
        //     return true;

        if (pos.x >= - card.width / 2 && pos.x <= card.width - 10 &&
            pos.y >= - card.height / 2 <= card.height - 10)
            return true;

        return false;
    },

});

TableLayer.AnimationTypes = {
    Beaten: "beaten",
    TakeAll: "take_all",
};