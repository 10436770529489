/**
 * Created by Vika O on 01.11.2024.
 */

/**
 * This callback is used in cocos2d-x framework.
 * @callback cocosClickCallback
 * @param {cc.Node} sender Object of MenuButton is clicked
 */

/**
 * Namespace for all Durak classes and functions.
 * @namespace
 */
var dd = dd || {};

/**
 * Namespace for all Durak UI classes and functions.
 * @namespace
 */
var dui = dui || {};

/**
 * Namespace for all Durak utils functions.
 * @namespace
 */
dd.utils = dd.utils || {};

/**
 * A namespace which contains all "data"-serializable classes
 * @namespace
 */
dd.data = {};

if (!String.prototype.format)
{
	/**
     * Formats string.
     * @example
     * var str = '123 {0}'.format(123);
     * // str = '123 123'
     * @returns {string}
     */
	String.prototype.format = function()
	{
		var args = arguments;
		return this.replace(/{(\d+)}/g, function(match, number)
		{
			return typeof args[number] !== "undefined"
				? args[number]
				: match
			;
		});
	};
}

if (!Array.prototype.top)
{
	/**
     * Returns last value of array.</br>
     * Can be uses if array is a stack.
     * @returns {*}
     */
	Array.prototype.top = function()
	{
		return this[this.length - 1];
	};
}

//Some holy shit for native platform:
//Args on native platform counted incorrect
if(cc.sys.isNative)
{
	cc.Node.prototype._addChild = cc.Node.prototype.addChild;

	cc.Node.prototype.addChild = function(child, zOrder, tag)
	{
		var args = [child];

		if(zOrder !== undefined)
			args.push(zOrder);

		if(tag !== undefined)
			args.push(tag);

		this._addChild.apply(this, args);
	};
}
