let CardsLayer = dui.DragAndDropLayout.extend(/** @lends CardsLayer# */{

    _gameLayout: null,

    ctor: function (gameLayout)
    {
        this._super(dui.DragAndDropLayout.Type.DRAG_AND_DROP, CardsLayer.DNDNAME);
        this.addDNDEventListener(this._dragCallback, this);

        this._gameLayout = gameLayout;

        this.setLayoutType(ccui.Layout.LINEAR_HORIZONTAL);
        this.setContentSize(0, GameLayer.CARDS_LAYER_HEIGHT);
    },

    getPlayerCards: function ()
    {
        return this.children;
    },

    addCard: function (rank, suit)
    {
        let card = new dui.Card(rank, suit);
        card.drawShadow();

        this.addChild(card);

        this._recalculateAligns();
    },

    _recalculateAligns: function ()
    {
        let cardsCount = this.children.length;

        var margin = Math.min(0,
            (Math.min(this._gameLayout.width * 0.9, GameLayer.CARDS_LAYER_MAX_WIDTH) - dui.CardWidth * cardsCount) / (cardsCount - 1));

        let cardAlign = new ccui.LinearLayoutParameter();
        cardAlign.setGravity(ccui.LinearLayoutParameter.CENTER_VERTICAL);
        cardAlign.setMargin(0, 0, margin, 0);

        for (let i = 0; i < cardsCount; ++i)
            this.children[i].setLayoutParameter(cardAlign.clone());

        this.setContentSize(dui.CardWidth * cardsCount + margin * (cardsCount - 1), this.height);
        // this.forceDoLayout();
        this._gameLayout.forceDoLayout();
    },

    _findElementByTouch: function(touch)
    {
        let pos = this.convertTouchToNodeSpace(touch);

        let cardsCount = this.children.length; // w = cw + (cw + cm) * (cc-1)

        let cardWidth = cardsCount > 1 ? (this.width - dui.CardWidth) / (cardsCount - 1) : dui.CardWidth;

        let i = Math.min(Math.floor(pos.x / cardWidth), cardsCount - 1);

        return this.children[i];
    },

    _cloneElement: function (element)
    {
        return new dui.Card(element.rank, element.suit);
    },

    _dragCallback: function(element, eventType, touch)
    {
        switch (eventType)
        {
            // case dui.DragAndDropLayout.Event.CLICKED:
            //     this._onClick(element, touch);
            //     break;
            case dui.DragAndDropLayout.Event.DRAG_CANCELLED:
                this._onDragCancel(element, touch);
                break;
            // case dui.DragAndDropLayout.Event.DROP:
            //     this._onDropCard(element, touch);
            //     break;
        }
    },

    // _onClick: function(element, touch)
    // {
    //     // console.log("!1", element, touch);
    // },

    _onDragCancel: function(element, touch)
    {
        switch(dd.GameState)
        {
            case dd.GameStates.Moving:
                this._gameLayout.registerMove(element, this._moveCallback.bind(this, element));
                break;
            case dd.GameStates.GivingMore:
                this._gameLayout.registerGiveMore(element, this._moveCallback.bind(this, element));
                break;
            case dd.GameStates.Responding:
                this._gameLayout.registerResponse(element, touch, this._moveCallback.bind(this, element));
                break;
        }
    },

    _moveCallback: function (card)
    {
        card.removeShadow();
        this._recalculateAligns();
    }

    // _onDropCard: function(element, touch)
    // {
    //     console.log("!2")
    // },
});

// class DurakCard {
//     static RANKS = ['6', '7', '8', '9', 'T', 'J', 'Q', 'K', 'A'];
//     static SUITS = ['C', 'D', 'H', 'S'];
//     static _INSTANCE_REGISTRY = new Map();
//
//     constructor(rank, suit) {
//         const [parsedRank, parsedSuit] = DurakCard._parseSuitAndRank(rank, suit);
//         this.numericRank = parsedRank;
//         this.suit = parsedSuit;
//
//         // Проверка, существует ли уже такая карта
//         const key = ${this.numericRank}-${this.suit};
//         if (DurakCard._INSTANCE_REGISTRY.has(key)) {
//             return DurakCard._INSTANCE_REGISTRY.get(key);
//         }
//
//         // Сохраняем экземпляр
//         DurakCard._INSTANCE_REGISTRY.set(key, this);
//     }
//
//     static _parseSuitAndRank(rank, suit) {
//         // Преобразование входных данных
//         if (typeof rank === 'string' && !suit) {
//             const string = rank.trim();
//             if (string.length !== 2) {
//                 throw new Error('Input string should be 2 chars long');
//             }
//             rank = string[0];
//             suit = string[1];
//         }
//
//         if (!DurakCard.SUITS.includes(suit.toUpperCase())) {
//             throw new Error('Invalid suit');
//         }
//
//         const rankIndex = DurakCard.RANKS.indexOf(rank.toUpperCase());
//         if (rankIndex === -1) {
//             throw new Error('Invalid rank');
//         }
//
//         return [rankIndex, suit.toUpperCase()];
//     }
//
//     static all() {
//         const cards = [];
//         for (let rank of DurakCard.RANKS) {
//             for (let suit of DurakCard.SUITS) {
//                 cards.push(new DurakCard(rank, suit));
//             }
//         }
//         return cards;
//     }
//
//     get rank() {
//         return DurakCard.RANKS[this.numericRank];
//     }
//
//     get numericSuit() {
//         return DurakCard.SUITS.indexOf(this.suit);
//     }
//
//     isLessThan(other, trump = null) {
//         if (!(other instanceof DurakCard)) {
//             throw new Error(`Cannot compare DurakCard and ${typeof other} instances`);
//         }
//
//         if (trump) {
//             if (!(trump instanceof DurakCard)) {
//                 throw new Error(`Trump should be DurakCard instance, not ${typeof trump}`);
//             }
//
//             if (this.suit === trump.suit && other.suit !== trump.suit) {
//                 return false;
//             }
//
//             if (this.suit !== trump.suit && other.suit === trump.suit) {
//                 return true;
//             }
//         }
//
//         return this.numericRank < other.numericRank ||
//             (this.numericRank === other.numericRank && this.suit < other.suit);
//     }
//
//     toString() {
//         return ${this.rank}${this.suit};
//     }
//
//     valueOf() {
//         return this.toString();
//     }
//
//     [Symbol.toPrimitive](hint) {
//         if (hint === 'string') {
//             return this.toString();
//         }
//         return this.numericRank; // Used for numerical comparisons
//     }
// }

CardsLayer.DNDNAME = "cards_drag_and_drop";