dui.EndGameLayer = ccui.Layout.extend(/** @lends GameLayer# */{

    ctor: function (type)
    {
        this._super();

        this.setBackGroundImage("Background/EndGameBackground.png");
        this.setBackGroundImageScale9Enabled(true);
        this.setCascadeOpacityEnabled(true);

        this.setContentSize(dui.EndGameLayer.WIDTH, dui.EndGameLayer.HEIGHT);
        this.setLayoutType(ccui.Layout.RELATIVE);

        let container = new ccui.VBox();

        let containerAlign = new ccui.RelativeLayoutParameter();
        containerAlign.setAlign(ccui.RelativeLayoutParameter.CENTER_IN_PARENT);
        container.setLayoutParameter(containerAlign);

        this.addChild(container);

        let text = new ccui.Text(type, dd.settings.fontName, dd.settings.fontSize);
        text.setTextAreaSize(cc.size(dui.EndGameLayer.TEXT_WIDTH, 0));
        text.setTextHorizontalAlignment(cc.TEXT_ALIGNMENT_CENTER);

        let textAlign = new ccui.LinearLayoutParameter;
        textAlign.setGravity(ccui.LinearLayoutParameter.CENTER_HORIZONTAL);
        textAlign.setMargin(0, 0, 0, 20);
        text.setLayoutParameter(textAlign);

        container.addChild(text);

        let playButton = new dui.Button(dd.spriteUtils.getButtonName("play", dd.NORMAL_STATE),
            dd.spriteUtils.getButtonName("play", dd.SELECTED_STATE),
            dd.spriteUtils.getButtonName("play", dd.DISABLED_STATE),
            ccui.Widget.LOCAL_TEXTURE);
        playButton.addClickEventListener(function (){});

        let playButtonAlign = new ccui.LinearLayoutParameter;
        playButtonAlign.setGravity(ccui.LinearLayoutParameter.CENTER_HORIZONTAL);
        playButton.setLayoutParameter(playButtonAlign);

        container.addChild(playButton);

        container.setContentSize(
            Math.max(text.width, playButton.width),
            text.height + 20 + playButton.height
        );
    }
});

dui.EndGameLayer.TEXT_WIDTH = 360;
dui.EndGameLayer.WIDTH = 400;
dui.EndGameLayer.HEIGHT = 300;
