/**
 * Created by Vika O on 01.11.2024.
 */

dd.sendCustomEvent = function(eventName, data)
{
    var event = new cc.EventCustom(eventName);

    if(data !== undefined)
        event.setUserData(data);

    cc.eventManager.dispatchEvent(event);
};

/**
 * Registers custom event listener.
 * @param {String} eventName
 * @param {Function} callback
 * @param {ccui.Widget|cc.Node|Number} nodeOrPriority
 * @returns {cc.EventListener}
 */
dd.registerCustomEventListener = function(eventName, callback, nodeOrPriority)
{
    var listener = cc.EventListener.create({
        event: cc.EventListener.CUSTOM,
        eventName: eventName,
        callback: callback
    });

    cc.eventManager.addListener(listener, nodeOrPriority);

    return listener;
};