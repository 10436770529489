/**
 * Created by Nikita Besshaposhnikov on 10.11.14.
 */
/**
 * Normal state string appendix for resource files.
 * @see dd.spriteUtils
 * @const
 * @type {String}
 * @default
 */
dd.NORMAL_STATE = "normal";
/**
 * Selected state string appendix for resource files.
 * @see dd.spriteUtils
 * @const
 * @type {String}
 * @default
 */
dd.SELECTED_STATE = "selected";
/**
 * Disabled state string appendix for resource files.
 * @see dd.spriteUtils
 * @const
 * @type {String}
 * @default
 */
dd.DISABLED_STATE = "disabled";
/**
 * Failed state string appendix for resource files.
 * @see dd.spriteUtils
 * @const
 * @type {String}
 * @default
 */
dd.FAILED_STATE = "failed";

/**
 * This namespace contains functions to work with ingame sprites.
 * @namespace
 */
dd.spriteUtils = {

	/**
     * @default
     * @private
     */
	_levelType: 0,
	/**
     * Stores sprite frames to delete between level changes.
     * @default
     * @private
     */
	_tempSpriteFrames: [],
	/**
     * Adds sprite frame to temp array and frame cache.
     * @param {String} file File path to sprite frames .plist
     */
	addTempSpriteFrames: function(file)
	{
		this._tempSpriteFrames.push(file);
		cc.spriteFrameCache.addSpriteFrames(file);
	},
	/**
     * Adds sprite frame only to temp array.
     * @param {String} file File path to sprite frames .plist
     */
	registerTempSpriteFrames: function(file)
	{
		this._tempSpriteFrames.push(file);
	},
	/**
     * Inits map textures for level type and tileset number.
     * @param {String} type A level type.
     * @param {Number} tileset Tileset number.
     */
	initMapTexture: function(type, tileset)
	{
		this._levelType = type;
		var file = dd.moduleUtils.getMapTileset(type, tileset);
		if (file)
			this.addTempSpriteFrames(file);
	},
	/**
     * Removes all temp sprite frames.
     */
	clear: function()
	{
		for(var i = 0; i < this._tempSpriteFrames.length; ++i)
			cc.spriteFrameCache.removeSpriteFramesFromFile(this._tempSpriteFrames[i]);

		this._tempSpriteFrames = [];
	},

	getCardSuitSprite: function(suit)
	{
		return `Cards/${dd.Suits[suit]}Symbol.png`;
	},

	getTimerSprite: function(type)
	{
		return `Timer/TimerBar-${type}.png`;
	},

	/**
     * Returns map texture name for level type and tileset.
     * @param {String} type A level type.
     * @param {Number} tileset Tileset number.
     * @returns {String}
     */
	getMapTextureName: function(type, tileset)
	{
		return dd.moduleUtils.getMapTileset(type, tileset);
	},

	/**
     * Load common sprite frames.
     */
	init: function()
	{
		cc.spriteFrameCache.addSpriteFrames("Robot Methods/common-methods.plist");
		cc.spriteFrameCache.addSpriteFrames("Conditions/common-conditions.plist");
		cc.spriteFrameCache.addSpriteFrames("Repeaters/common-repeaters.plist");
		cc.spriteFrameCache.addSpriteFrames("System/iconset.plist");
		cc.spriteFrameCache.addSpriteFrames("System/iconset_new.plist");
		cc.spriteFrameCache.addSpriteFrames("System/interface.plist");
		cc.spriteFrameCache.addSpriteFrames("Background/common-background.plist");

		dd.appUtils.addExtraSpriteFrames();
	},

	/**
     * Returns icon file name.
     * @param {String} name
     * @param {String} [state=dd.NORMAL_STATE]
     * @returns {String}
     */
	getIconName: function(name, state)
	{
		if(state === undefined)
			state = dd.NORMAL_STATE;

		return "{0}Icon-{1}.png".format(name, state);
	},

	/**
	 * Returns icon file name.
	 * @param {String} name
	 * @param {String} [state=dd.NORMAL_STATE]
	 * @returns {String}
	 */
	getButtonName: function(name, state)
	{
		if(state === undefined)
			state = dd.NORMAL_STATE;

		return "Buttons/{0}Button-{1}.png".format(name, state);
	},

	/**
     *
     * @param {String} name
     * @param {String} [state=dd.NORMAL_STATE]
     * @returns {cc.Sprite}
     */
	getIconSprite: function(name, state)
	{
		if(state === undefined)
			state = dd.NORMAL_STATE;

		return new cc.Sprite(this.getIconSpriteFrame(name, state));
	},

	/**
     *
     * @param {String} name
     * @param {String} [state=dd.NORMAL_STATE]
     * @returns {cc.SpriteFrame}
     */
	getIconSpriteFrame: function(name, state)
	{
		if(state === undefined)
			state = dd.NORMAL_STATE;

		return cc.spriteFrameCache.getSpriteFrame("{0}Icon-{1}.png".format(name, state));
	},

	/**
     *
     * @param {String} name
     * @returns {String}
     */
	getInterfaceElementName: function(name)
	{
		return "{0}.png".format(name);
	},

	/**
     *
     * @param {String} name
	 * @param {String} [state=dd.NORMAL_STATE]
     * @returns {String}
     */
	getNodeLayerElementName: function(name, state)
	{
		return "{0}-{1}.png".format(name, state);
	},

	/**
     *
     * @param {String} name
     * @returns {cc.Sprite}
     */
	getInterfaceElementSprite: function(name)
	{
		return new cc.Sprite(this.getInterfaceElementFrame(name));
	},

	/**
     *
     * @param {String} name
     * @returns {cc.SpriteFrame}
     */
	getInterfaceElementFrame: function(name)
	{
		return cc.spriteFrameCache.getSpriteFrame("{0}.png".format(name));
	},

	/**
     *
     * @param {String} method
     * @param {String} [state=dd.NORMAL_STATE]
     * @returns {String}
     */
	getRobotMethodSpriteName: function(method, state)
	{
		if(state === undefined)
			state = dd.NORMAL_STATE;

		if(cc.isNumber(method))
			method = dd.EMPTY_METHOD;

		return "{0}-{1}.png".format(LocalizedImage(method), state);
	},

	/**
	 *
	 * @param {String} number
	 * @param {String} [state=dd.NORMAL_STATE]
	 * @returns {String}
	 */
	getNumberButtonSpriteName: function(number, state)
	{
		if(state === undefined)
			state = dd.NORMAL_STATE;

		return "n{0}-{1}.png".format(number, state);
	},
	/**
     *
     * @param {String} method
     * @param {String} [state=dd.NORMAL_STATE]
     * @returns {String}
     */
	getEmptyMethodSpriteName: function(state)
	{
		if(state === undefined)
			state = dd.NORMAL_STATE;

		return "empty-method-{0}.png".format( state);
	},

	/**
	 *
	 * @param {String} method
	 * @param {String} [state=dd.NORMAL_STATE]
	 * @returns {String}
	 */
	getMethodCallSpriteName: function(state)
	{
		if(state === undefined)
			state = dd.NORMAL_STATE;

		return "method-call-{0}.png".format( state);
	},
	/**
     *
     * @param {String} method
     * @param {String} [state=dd.NORMAL_STATE]
     * @returns {String}
     */
	getStatementSpriteName: function(name, state)
	{
		if(state === undefined)
			state = dd.NORMAL_STATE;

		return "statement-{0}-{1}.png".format(name, state);
	},

	/**
     *
     * @param {String} method
     * @param {String} [state=dd.NORMAL_STATE]
     * @returns {cc.Sprite}
     */
	getRobotMethodSprite: function(method, state)
	{
		if(state === undefined)
			state = dd.NORMAL_STATE;

		return new cc.Sprite(this.getRobotMethodSpriteFrame(method, state));
	},
	/**
	 *
	 * @param {String} method
	 * @param {String} [state=dd.NORMAL_STATE]
	 * @returns {cc.Sprite}
	 */

	getRobotVariableSprite: function(method, state)
	{
		if(state === undefined)
			state = dd.NORMAL_STATE;

		return new cc.Sprite(this.getRobotVariableSpriteFrame(method, state));
	},

	/**
     *
     * @param {String} method
     * @param {String} [state=dd.NORMAL_STATE]
     * @returns {cc.SpriteFrame}
     */
	getRobotMethodSpriteFrame: function(method, state)
	{
		if(state === undefined)
			state = dd.NORMAL_STATE;

		return cc.spriteFrameCache.getSpriteFrame("{0}-{1}.png".format(method, state));
	},
	/**
	 *
	 * @param {String} method
	 * @param {String} [state=dd.NORMAL_STATE]
	 * @returns {cc.SpriteFrame}
	 */
	getRobotVariableSpriteFrame: function(method, state)
	{
		if(state === undefined)
			state = dd.NORMAL_STATE;

		return cc.spriteFrameCache.getSpriteFrame("cond-{0}-{1}.png".format(method, state));
	},

	/**
     *
     * @param {String} condition
     * @param {String} [state=dd.NORMAL_STATE]
     * @returns {String}
     */
	getRobotConditionSpriteName: function(condition, state)
	{
		if(state === undefined)
			state = dd.NORMAL_STATE;

		return "cond-{0}-{1}.png".format(condition, state);
	},
	/**
	 *
	 * @param {String} condition
	 * @param {String} [state=dd.NORMAL_STATE]
	 * @returns {String}
	 */
	getConditionStatementSpriteName: function(type, state)
	{
		if(state === undefined)
			state = dd.NORMAL_STATE;

		return "condStat-{0}-{1}.png".format(type, state);
	},

	/**
	 *
	 * @param {String} condition
	 * @param {String} [state=dd.NORMAL_STATE]
	 * @returns {String}
	 */
	getExprSpriteName: function(value, state)
	{
		if(state === undefined)
			state = dd.NORMAL_STATE;

		return "expr-{0}-{1}.png".format(value, state);
	},

	/**
	 *
	 * @param {String} condition
	 * @param {String} [state=dd.NORMAL_STATE]
	 * @returns {String}
	 */
	getSignSpriteName: function(sign, state)
	{
		if(state === undefined)
			state = dd.NORMAL_STATE;

		return "{0}-sign-{1}.png".format(sign, state);
	},

	/**
	 *
	 * @param {String} condition
	 * @param {String} [state=dd.NORMAL_STATE]
	 * @returns {String}
	 */
	getAllocSpriteName: function(value, state)
	{
		if(state === undefined)
			state = dd.NORMAL_STATE;

		return "empty-method-{0}.png".format(state);
	},

	/**
	 *
	 * @param {String} condition
	 * @param {String} [state=dd.NORMAL_STATE]
	 * @returns {String}
	 */
	getConditionStatementSpriteName: function(type, state)
	{
		if(state === undefined)
			state = dd.NORMAL_STATE;

		return "{0}-{1}.png".format(LocalizedImage("condStat-{0}".format(type)), state);
	},

	/**
	 *
	 * @param {String|Number} repeater
	 * @param {String} [state=dd.NORMAL_STATE]
	 * @returns {cc.Sprite}
	 */
	getNumberSpriteName: function(state)
	{
		if(state === undefined)
			state = dd.NORMAL_STATE;

		return "number-round-{0}.png".format( state);
	},

	/**
	 *
	 * @param {String|Number} repeater
	 * @param {String} [state=dd.NORMAL_STATE]
	 * @returns {cc.Sprite}
	 */
	getIntMethodSpriteName: function(value, state)
	{
		if(state === undefined)
			state = dd.NORMAL_STATE;

		return "{0}-{1}.png".format(value, state);
	},

	/**
	 *
	 * @param {String|Number} repeater
	 * @param {String} [state=dd.NORMAL_STATE]
	 * @returns {cc.Sprite}
	 */
	getBoolSpriteName: function(state)
	{
		if(state === undefined)
			state = dd.NORMAL_STATE;

		return "bool-bg-{0}.png".format( state);
	},

	/**
     *
     * @param {String} condition
     * @param {String} [state=dd.NORMAL_STATE]
     * @returns {cc.Sprite}
     */
	getRobotConditionSprite: function(condition, state)
	{
		if(state === undefined)
			state = dd.NORMAL_STATE;

		return new cc.Sprite(this.getRobotConditionSpriteFrame(condition, state));
	},

	/**
     *
     * @param {String} condition
     * @param {String} [state=dd.NORMAL_STATE]
     * @returns {cc.SpriteFrame}
     */
	getRobotConditionSpriteFrame: function(condition, state)
	{
		if(state === undefined)
			state = dd.NORMAL_STATE;

		return cc.spriteFrameCache.getSpriteFrame("cond-{0}-{1}.png".format(condition, state));
	},

	/**
     *
     * @param {String|Number} repeater
     * @param {String} [state=dd.NORMAL_STATE]
     * @returns {String}
     */
	getRepeaterSpriteName: function(repeater, state)
	{
		if(state === undefined)
			state = dd.NORMAL_STATE;

		return "{0}-{1}.png".format(LocalizedImage("rep-{0}".format(repeater)), state);

			//return "rep-{0}-{1}-{2}.png".format(repeater, state, iterNum);
	},

	/**
     *
     * @param {String|Number} repeater
     * @param {String} [state=dd.NORMAL_STATE]
     * @returns {cc.Sprite}
     */
	getRepeaterSprite: function(repeater, state)
	{
		if(state === undefined)
			state = dd.NORMAL_STATE;

		return new cc.Sprite(this.getRepeaterSpriteFrame(repeater, state));
	},

	/**
     *
     * @param {String|Number} repeater
     * @param {String} [state=dd.NORMAL_STATE]
     * @returns {cc.SpriteFrame}
     */
	getRepeaterSpriteFrame: function(repeater, state)
	{
		if(state === undefined)
			state = dd.NORMAL_STATE;

		return cc.spriteFrameCache.getSpriteFrame("rep-{0}-{1}.png".format(repeater, state));
	},

	/**
     * Returns map grass tile filename.
     * @param {Number | String} tile
     * @returns {String}
     */
	getMapGrassTileName: function(tile)
	{
		return "mapElement{0}.png".format(tile);
	},

	/**
     *
     * @param {Number | String} tile
     * @returns {cc.Sprite}
     */
	getMapGrassTile: function(tile)
	{
		return new cc.Sprite(this.getMapGrassTileFrame(tile));
	},

	/**
     *
     * @param {Number | String} tile
     * @returns {cc.SpriteFrame}
     */
	getMapGrassTileFrame: function(tile)
	{
		return cc.spriteFrameCache.getSpriteFrame("mapElement{0}.png".format(tile));
	},

	/**
     *
     * @param {Number | String} wall
     * @returns {cc.Sprite}
     */
	getMapWallTile: function(wall)
	{
		return new cc.Sprite(this.getMapWallTileFrame(wall));
	},

	/**
     *
     * @param {Number | String} wall
     * @returns {cc.SpriteFrame}
     */
	getMapWallTileFrame: function(wall, orientation)
	{
		if(orientation === undefined || orientation === dd.MapLayer2D.Orientation.Iso)
			return cc.spriteFrameCache.getSpriteFrame("wall-{0}.png".format(wall));

		if(orientation === dd.MapLayer2D.Orientation.Ortho)
			return cc.spriteFrameCache.getSpriteFrame("wall-{0}-top.png".format(wall));

	},

	/**
     * Returns map tile filename.
     * @param {String} tile
     * @returns {String}
     */
	getMapTileName: function(tile)
	{
		return "{0}.png".format(tile);
	},

	/**
     *
     * @param {String} tile
     * @returns {cc.Sprite}
     */
	getMapTile: function(tile)
	{
		return new cc.Sprite(this.getMapTileFrame(tile));
	},

	/**
     *
     * @param {String} tile
     * @returns {cc.SpriteFrame}
     */
	getMapTileFrame: function(tile)
	{
		return cc.spriteFrameCache.getSpriteFrame("{0}.png".format(tile));
	}
};
