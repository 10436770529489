/**
 * Created by danilaeremin on 27.10.15.
 */

/**
* @class dui.Text Label with default font.
* @extends ccui.Text
* @constructor
* @param {String} text Label text.
* @param {Number} [fontSize] Size of font for label.
*/
dui.Text = ccui.Text.extend(/** @lends dui.Text */{
	ctor: function(text, fontSize)
	{
		if (fontSize === undefined)
			fontSize = dd.settings.fontSize;

		this._super(text, dd.settings.fontName, fontSize);
	}
});
