/**
 * Created by Nikita Besshaposhnikov on 26.02.16.
 */

/**
 * This namespace is used for api calls of durak server.
 * @namespace
 */
dd.apiServerUtils = {

	/**
     * Ok network status
     * @const
     * @type {String}
     * @default
     */
	OK_STATUS: "ok",
	/**
     * Default timeout for requests
     * @const
     * @type {Number}
     * @default
     */
	DEFAULT_TIMEOUT: 10000,

	/**
     * Send request using XHR.
     * @param {Object} params
     * @param {Function} params.callback
	 * @param {String} params.method Method type for request
     * @param {String} params.url Url for request
     * @param {String} params.request Request path for specified url
	 * @param {Boolean} [params.withAuth] send with auth
	 * @param {Object} [params.data] Data for request
     * @param {Number} [params.timeout] Custom timeout for request
     * @private
     */
	_sendRequest: function(params)
	{
		var xhr = cc.loader.getXMLHttpRequest();

		var request = params.request +((params.request.indexOf("?") > -1) ? "&" : "?") + "app=";
		params.request = request;

		xhr.open(params.method, params.url + params.request, true);

		xhr.setRequestHeader("Content-Type", "application/json;charset=UTF-8");

		if(params.withAuth && dd.settings.getAccessToken())
			xhr.setRequestHeader("x-access-token", dd.settings.getAccessToken());

		var timeout = this.DEFAULT_TIMEOUT;

		if(cc.sys.isNative)
			dd.setHttpRequestTimeout(timeout);
		else
			xhr.timeout = timeout;

		xhr.ontimeout = function ()
		{
			if (params.hasOwnProperty("callback"))
				params.callback({text: "Timeout", status: xhr.status}, -1);
			else
				throw new Error("Timeout");
		};

		xhr.onerror = function (error)
		{
			var errorText = error ? JSON.stringify(error) : "Unknown XHR error";

			if (params.hasOwnProperty("callback"))
				params.callback({text: errorText, status: -1});
			else
				throw new Error(errorText);
		};

		xhr.onreadystatechange = function ()
		{
			if (xhr.readyState === 4 )
			{
				var response = null;

				try
				{
					response = JSON.parse(xhr.responseText);
				}
				catch(e)
				{
					response = null;
				}

				var hasCheckResponse = params.hasOwnProperty("checkResponse");

				if (!hasCheckResponse || (hasCheckResponse && params.checkResponse))
				{
					if (!response)
					{
						params.callback({text: "Incorrect response", status: xhr.status});
						return;
					}
				}

				if((xhr.status >= 200 && xhr.status <= 207))
				{
					if(params.hasOwnProperty("callback"))
						params.callback(null, response);
				}
				else if (xhr.status !== 0)
				{
					if (params.hasOwnProperty("callback"))
					{
						var text = response.error
							? response.error + ": " + response.error_description
							: response.message || "Empty error";

						params.callback({
							text: text,
							status: xhr.status
						});
					}

					xhr.status = 200;
				}
			}

		};

		xhr.send(params.hasOwnProperty("data") ? JSON.stringify(params.data) : "");
	},

	/**
     * Send request with access token, If server returns '401' tries to update it.
     * @param {Object} params
     * @param {Function} params.callback
     * @param {String} params.method Method type for request
     * @param {String} params.url Url for request
     * @param {String} params.request Request path for specified url
     * @param {?Object} [params.data] Data for request
     * @param {Number} [params.timeout] Custom timeout for request
     * @private
     */
	_sendRequestWithToken: function(params)
	{
		if (dd.settings.getAccessToken())
		{
			params.withAuth = true;

			var callback = params.callback;

			params.callback = function (error, response)
			{
				if (error && error.status === 401)
				{
					this.updateAccessToken(function (error, updateResponse)
					{
						if(!error)
						{
							dd.settings.setAccessToken(updateResponse.access_token);
							dd.settings.setRefreshToken(updateResponse.refresh_token);

							params.callback = callback;
							this._sendRequest(params);
						}
						else
						{
							callback(error);
						}

					}.bind(this)
					);
				}
				else if(error)
				{ callback(error); }
				else
				{ callback(null, response); }

			}.bind(this);

			this._sendRequest(params);
		}
		else
		{
			this._sendRequest(params);
		}
	},

	/**
     * Requests access and refresh token for server api.
     * @param {function} callback
     * @param {String} login
     * @param {String} pass
     */
	login: function(callback, login, pass)
	{
		this._sendRequest({
			method: "POST",
			url: dd.appConfig.authAddress,
			request: "app/authorize/token",
			data: {
				login: login,
				password: pass,
			},
			callback: callback
		});
	},

	/**
	 * Requests access and refresh token for server api.
	 * @param {function} callback
	 * @param {String} code
	 * @param {String} codeVerifier
	 * @param {String} redirectUri
	 */
	loginByCode: function(callback, code, codeVerifier, redirectUri)
	{
		this._sendRequest({
			method: "POST",
			url: dd.appConfig.authAddress,
			request: "app/authorize/oauth/token",
			data: {
				code: code,
				code_verifier: codeVerifier,
				redirect_uri: redirectUri,
			},
			callback: callback
		});
	},

	/**
	 * Requests access token by refresh token
	 * @param {function} callback
	 */
	updateAccessToken: function(callback)
	{
		this._sendRequest({
			method: "POST",
			url: dd.appConfig.authAddress,
			request: "app/authorize/token/refresh",
			data: {
				refresh_token: dd.settings.getRefreshToken(),
			},
			callback: callback
		});
	},

	/**
     *Registration.
     * @param {function} callback
     * @param {String} mail
     * @param {String} password
     * @param {String} name
     * @param {String} surname
     */
	register: function(callback, mail, password, name, surname)
	{
		this._sendRequest({
			method: "POST",
			url: dd.appConfig.authAddress,
			request: "app/registration/register",
			data: {
				type: "user",
				mail: mail,
				password: password,
				name: name,
				surname: surname
			},
			callback: callback,
			checkResponse: false
		});
	}
};
