dd.GameStates = {
    Waiting: "waiting",
    Moving: "moving",
    Responding: "responding",
    GivingMore: "giving_more",
};

dd.GameState = dd.GameStates.Waiting;

let GameLayer = ccui.Layout.extend(/** @lends GameLayer# */{

    _socket: null,

    _table: null,

    _cardsLayer: null,
    _opponentLayer: null,

    _timer: null,

    _beatenSprites: [],
    _stackSprites: [],

    _numberLayer: null,
    _opponentCardsNumberLayer: null,

    ctor: function (socket)
    {
        this._super();

        this._socket = socket;

        socket.addEventListener("message", this._onMessage.bind(this));

        this.setBackGroundImage("Background/TableBackground.png");
        this.setBackGroundImageScale9Enabled(true);
        this.setCascadeOpacityEnabled(true);
        // this.setBackGroundImageCapInsets(cc.rect(80, 90, 100, 70));

        this.setContentSize(dd.settings.getScreenSize());
        this.setLayoutType(ccui.Layout.RELATIVE);

        this._table = new TableLayer(this);

        let tableAlign = new ccui.RelativeLayoutParameter();
        tableAlign.setAlign(ccui.RelativeLayoutParameter.CENTER_IN_PARENT);
        this._table.setLayoutParameter(tableAlign);

        this.addChild(this._table, 101);

        this._cardsLayer = new CardsLayer(this);

        let cardsLayerAlign = new ccui.RelativeLayoutParameter();
        cardsLayerAlign.setAlign(ccui.RelativeLayoutParameter.PARENT_BOTTOM_CENTER_HORIZONTAL);
        cardsLayerAlign.setMargin(0, 0, 0, GameLayer.CARDS_LAYER_MARGIN_BOTTOM);
        this._cardsLayer.setLayoutParameter(cardsLayerAlign);

        this.addChild(this._cardsLayer);

        this._opponentLayer = new ccui.HBox();
        this._opponentLayer.setContentSize(0, GameLayer.OPPONENT_LAYER_HEIGHT);

        let opponentLayerAlign = new ccui.RelativeLayoutParameter();
        opponentLayerAlign.setAlign(ccui.RelativeLayoutParameter.PARENT_TOP_CENTER_HORIZONTAL);
        opponentLayerAlign.setMargin(0, GameLayer.OPPONENT_LAYER_MARGIN_TOP, 0, 0);
        opponentLayerAlign.setRelativeName("opponent");
        this._opponentLayer.setLayoutParameter(opponentLayerAlign);

        this.addChild(this._opponentLayer);

        this._timer = new dui.Timer(60);
        
        let timerAlign = new ccui.RelativeLayoutParameter();
        timerAlign.setMargin(0, GameLayer.BORDER, 0, GameLayer.BORDER);
        this._timer.setLayoutParameter(timerAlign);

        this._drawInterfaceButtons();
        this._drawBeatenCards();
    },

    _onMessage: function (event)
    {
        let data = JSON.parse(event.data);
        if (data.type !== "timer_update")
            console.log(")))", data.type, data);

        switch (data.type)
        {
            case "reconnect": // todo: это надо переписать
                dd.StackLength = data.stackLength;
                let beatenCount = 36 - dd.StackLength - data.cards.length - data.opponentCardsLength;

                for (let [card, greaterCard] of Object.entries(data.cardsOnTable))
                {
                    card = new dui.Card(card);
                    this._table.addCard(card);
                    --beatenCount;

                    if (greaterCard !== "None")
                    {
                        greaterCard = new dui.Card(greaterCard);
                        card.addGreaterCard(greaterCard);
                        --beatenCount;
                    }
                }

                while (dd.StackLength < this._stackSprites.length)
                {
                    let back = this._stackSprites.pop();
                    back.removeFromParent();
                }

                for (let i = 0; i < Math.min(this._beatenSprites.length, beatenCount); i++)
                    this._beatenSprites[i].setVisible(true);

                let notBeatenCardsCount = this._table.getNotBeatenCardsCount(); //  && (this._firstRow.children.length > 0) ??

                if (data.moving && notBeatenCardsCount === 0 && data.state === "moving")
                    this._beatenButton.setVisible(true);

                if (!data.moving && notBeatenCardsCount > 0 && data.state === "moving")
                    this._takeButton.setVisible(true);

                if (data.moving && data.state === "giving_more")
                    this._givingMoreButton.setVisible(true);

                // refresh buttons
                this.addChild(this._timer);
                this._resetTimer(data.state === "moving" && (data.moving && notBeatenCardsCount === 0 || !data.moving && notBeatenCardsCount > 0) || data.moving && data.state === "giving_more");

            case "start_game": // todo: эту ветку тоже подправить
                this._drawOpponentAvatar();

                dd.Trump.rank = data.trump[0];
                dd.Trump.suit = data.trump[1];

                for (let i = 0; i < data.cards.length; i++)
                    this._addPlayerCard(data.cards[i][0], data.cards[i][1]);

                for (let i = 0; i < data.opponentCardsLength; i++)
                    this._addOpponentCard();

                if (data.moving)
                    dd.GameState = dd.GameStates.Moving;
                else
                    dd.GameState = dd.GameStates.Responding;

                if (data.type === "start_game")
                {
                    dd.StackLength -= (data.cards.length + data.opponentCardsLength);

                    this.addChild(this._timer);
                    this._resetTimer(data.moving);
                }

                this._drawCardStack();

                this.forceDoLayout();
                break;

            case "dealing":
                if (dd.GameState !== dd.GameStates.Waiting)
                {
                    for (let i = 0; i < data.cards.length; i++)
                        this._playerTakesCard(data.cards[i][0], data.cards[i][1]);
                }

                if (dd.GameState !== dd.GameStates.GivingMore)
                {
                    for (let i = 0; i < data.opponentCardsLength; i++)
                        this._opponentTakesCard();
                }

                this._numberLayer.updateText(dd.StackLength);

                // todo: это говнокод
                if (dd.GameState === dd.GameStates.Responding)
                {
                    dd.GameState = dd.GameStates.Moving;
                    this._runBeatenAnimation();
                }
                else if (dd.GameState === dd.GameStates.Moving || dd.GameState === dd.GameStates.Waiting)
                    dd.GameState = dd.GameStates.Responding;
                else if (dd.GameState === dd.GameStates.GivingMore)
                    dd.GameState = dd.GameStates.Moving;

                this._givingMoreButton.setVisible(false);
                this._resetTimer(dd.GameState === dd.GameStates.Moving);

                break;

            case "opponents_move":
                this._opponentsMove(data.card[0], data.card[1]);
                break;

            case "opponents_response":
                this._opponentsResponse(data.attack_card, data.defend_card);
                break;

            case "opponents_transfer":
                dd.GameState = dd.GameStates.Responding;
                this._resetTimer(true);

                this._opponentsMove(data.card[0], data.card[1]);
                break;

            case "opponent_takes":
                dd.GameState = dd.GameStates.GivingMore;

                this._givingMoreButton.setVisible(true);
                this._resetTimer(true);

                break;

            case "end_take":
                this._runPlayerTakesAnimation();
                break;

            case "game_over":
                let message = "";

                switch (data.reason)
                {
                    case "timeout":
                        if (data.winner)
                            message = "Your opponent has run out of time. You win!";
                        else
                            message = "You've run out of time. You loose!";
                        break;
                    case "win":
                        message = "You win!";
                        break;
                    case "loose":
                        message = "You loose!";
                        break;
                    case "draw":
                        message = "Draw!";
                        break;
                }

                this._constructEndGameLayer(message);
                break;
            case "timer_update":
                this._timer.setTime(data.remaining_time);
                break;
        }
    },
    
    // Функйции, рисующие объекты интерфеса

    /**
     * Функция, рисующая кнопки действий (беру, бито, пас)
     * @private
     */
    _drawInterfaceButtons: function()
    {
        this._takeButton = new dui.Button(dd.spriteUtils.getButtonName("take", dd.NORMAL_STATE),
            dd.spriteUtils.getButtonName("take", dd.SELECTED_STATE),
            dd.spriteUtils.getButtonName("take", dd.DISABLED_STATE),
            ccui.Widget.LOCAL_TEXTURE);
        this._takeButton.addClickEventListener(this.registerTake.bind(this));

        let takeButtonAlign = new ccui.RelativeLayoutParameter();
        takeButtonAlign.setAlign(ccui.RelativeLayoutParameter.PARENT_RIGHT_CENTER_VERTICAL);
        takeButtonAlign.setMargin(0, 0, GameLayer.BORDER, 0);
        this._takeButton.setLayoutParameter(takeButtonAlign);
        this._takeButton.setVisible(false);

        this.addChild(this._takeButton, 2);

        this._beatenButton = new dui.Button(dd.spriteUtils.getButtonName("beaten", dd.NORMAL_STATE),
            dd.spriteUtils.getButtonName("beaten", dd.SELECTED_STATE),
            dd.spriteUtils.getButtonName("beaten", dd.DISABLED_STATE),
            ccui.Widget.LOCAL_TEXTURE);
        this._beatenButton.addClickEventListener(this.registerBeaten.bind(this));

        let beatenButtonAlign = new ccui.RelativeLayoutParameter();
        beatenButtonAlign.setAlign(ccui.RelativeLayoutParameter.PARENT_RIGHT_CENTER_VERTICAL);
        beatenButtonAlign.setMargin(0, 0, GameLayer.BORDER, 0)
        this._beatenButton.setLayoutParameter(beatenButtonAlign);
        this._beatenButton.setVisible(false);

        this.addChild(this._beatenButton, 2);

        this._givingMoreButton = new dui.Button(dd.spriteUtils.getButtonName("pass", dd.NORMAL_STATE),
            dd.spriteUtils.getButtonName("pass", dd.SELECTED_STATE),
            dd.spriteUtils.getButtonName("pass", dd.DISABLED_STATE),
            ccui.Widget.LOCAL_TEXTURE);
        this._givingMoreButton.addClickEventListener(this.registerEndGivingMore.bind(this));

        let givingMoreButtonAlign = new ccui.RelativeLayoutParameter();
        givingMoreButtonAlign.setAlign(ccui.RelativeLayoutParameter.PARENT_RIGHT_CENTER_VERTICAL);
        givingMoreButtonAlign.setMargin(0, 0, GameLayer.BORDER, 0);
        this._givingMoreButton.setLayoutParameter(givingMoreButtonAlign);
        this._givingMoreButton.setVisible(false);

        this.addChild(this._givingMoreButton, 2);
    },

    /**
     * Функция, рисующая колоду карт
     * @private
     */
    _drawCardStack: function ()
    {
        let cardStack = new ccui.Layout();

        let layerAlign = new ccui.RelativeLayoutParameter();
        layerAlign.setAlign(ccui.RelativeLayoutParameter.PARENT_LEFT_CENTER_VERTICAL);

        if (cc.sys.isMobile)
            layerAlign.setMargin(GameLayer.OPPONENT_LAYER_MARGIN_TOP, 0, 0, 0);
        else
            layerAlign.setMargin(GameLayer.BORDER, 0, 0, 0);

        layerAlign.setRelativeName("card_stack");
        cardStack.setLayoutParameter(layerAlign);

        this.addChild(cardStack);

        this._numberLayer = new dui.NumberLayer(dd.StackLength);

        let numberAlign = new ccui.RelativeLayoutParameter();
        numberAlign.setAlign(ccui.RelativeLayoutParameter.LOCATION_BELOW_CENTER);
        numberAlign.setMargin(0, GameLayer.BORDER, 0, 0);
        numberAlign.setRelativeToWidgetName("card_stack");
        this._numberLayer.setLayoutParameter(numberAlign);

        this.addChild(this._numberLayer);

        let trumpCard = new dui.Card(dd.Trump.rank, dd.Trump.suit);
        trumpCard.setAnchorPoint(cc.p(0.5, 0.5));
        trumpCard.setRotation(90);
        trumpCard.setPosition(cc.p(trumpCard.width,trumpCard.height / 2));

        cardStack.addChild(trumpCard);
        this._stackSprites.push(trumpCard);

        for (let i = 0; i < 6; ++i)
        {
            let cardBack = new cc.Sprite("Cards/CardBack.png");

            cardBack.setAnchorPoint(cc.p(0, 0));
            cardBack.setPosition(cc.p(- i, 10 * i));

            cardStack.addChild(cardBack);
            this._stackSprites.push(cardBack);
        }

        cardStack.setContentSize(trumpCard.width, trumpCard.height + 50);
    },

    /**
     * Функция, рисующая стопку "бито" (в начале игры стопка не видна)
     * @private
     */
    _drawBeatenCards: function ()
    {
        for (let i = 0; i < 6; ++i)
        {
            let cardBack = new cc.Sprite("Cards/CardBack.png");

            cardBack.setRotation(90 + 10 * (i % 2));
            cardBack.setAnchorPoint(cc.p(0, 1));

            if (cc.sys.isMobile)
                cardBack.setPosition(cc.p(this.width + cardBack.height / 2 + 10 * i, this.height/2 + 10 * i));
            else
                cardBack.setPosition(cc.p(this.width - 20 + 10 * i, this.height/2 + 10 * i));

            cardBack.setVisible(false);

            this.addChild(cardBack);
            this._beatenSprites.push(cardBack);
        }
    },

    _drawOpponentAvatar: function ()
    {
        this._opponentCardsNumberLayer = new dui.NumberLayer(0);

        let numberAlign = new ccui.RelativeLayoutParameter();
        numberAlign.setAlign(ccui.RelativeLayoutParameter.LOCATION_RIGHT_OF_TOPALIGN);
        numberAlign.setRelativeToWidgetName("opponent");
        numberAlign.setMargin(10, GameLayer.BORDER - GameLayer.OPPONENT_LAYER_MARGIN_TOP, 0, 0);
        this._opponentCardsNumberLayer.setLayoutParameter(numberAlign);

        this.addChild(this._opponentCardsNumberLayer, 10);
    },

    /**
     * Функция, рисующая лэер, информируюший об окончании игры
     * @private
     */
    _constructEndGameLayer: function (type)
    {
        let endGameLayer = new dui.EndGameLayer(type);

        let align = new ccui.RelativeLayoutParameter();
        align.setAlign(ccui.RelativeLayoutParameter.CENTER_IN_PARENT);
        endGameLayer.setLayoutParameter(align);

        this.addChild(endGameLayer, 102);

        this._timer.stop();
    },

    /**
     * Функция, рисующая лэер, информируюший об окончании игры
     * @private
     */
    _resetTimer: function (isPlayerTurn)
    {
        console.log("reset timer", isPlayerTurn);
        if (isPlayerTurn)
            this._timer.getLayoutParameter().setAlign(ccui.RelativeLayoutParameter.PARENT_BOTTOM_CENTER_HORIZONTAL);
        else
            this._timer.getLayoutParameter().setAlign(ccui.RelativeLayoutParameter.PARENT_TOP_CENTER_HORIZONTAL);

        this.forceDoLayout();

        this._timer.restart();
    },

    //Функции, касающиеся ходов

    registerMove: function (card, callback)
    {
        let notBeatenCardsCount = this._table.getNotBeatenCardsCount();

        if (this._opponentLayer.children.length <= notBeatenCardsCount || !this._table.canAddCard(card))
            return;

        card.removeFromParent();
        this._table.addCard(card);
        callback();

        if (notBeatenCardsCount === 0)
        {
            this._resetTimer(false);
            this._beatenButton.setVisible(false);
        }

        this._socket.send(JSON.stringify({action_type: "move", card: card.getString()}));
    },

    registerResponse: function (card, touch, callback)
    {
        if (this._table.detectTransfer(card, touch))
        {
            card.removeFromParent();
            this._table.addCard(card);
            callback();

            dd.GameState = dd.GameStates.Moving;
            this._resetTimer(false);
            this._takeButton.setVisible(false);

            this._socket.send(JSON.stringify({action_type: "transfer", card: card.getString()}));
        }
        else
        {
            let beatenCard = this._table.findBeatenCard(card, touch);

            if (!beatenCard)
                return;

            card.removeFromParent();
            beatenCard.addGreaterCard(card);
            callback();

            if (this._table.getNotBeatenCardsCount() === 0)
            {
                this._resetTimer(false);
                this._takeButton.setVisible(false);
            }

            this._socket.send(JSON.stringify({action_type: "response", attack_card: beatenCard.getString(), defend_card: card.getString()}));
        }
    },

    registerGiveMore: function (card, callback)
    {
        if (this._opponentLayer.children.length <= this._table.getNotBeatenCardsCount() || !this._table.canAddCard(card))
            return;

        card.removeFromParent();
        this._table.addCard(card);
        callback();

        this._socket.send(JSON.stringify({action_type: "giving_more", card: card.getString()}));
    },

    registerTake: function ()
    {
        this._resetTimer(false);
        this._takeButton.setVisible(false);

        this._socket.send(JSON.stringify({action_type: "take"}));

        dd.GameState = dd.GameStates.Waiting;
    },

    registerEndGivingMore: function (){
        this._socket.send(JSON.stringify({action_type: "end_giving_more"}));

        this._runOpponentTakesAnimation();
    },

    registerBeaten: function (){
        this._runBeatenAnimation();
        this._beatenButton.setVisible(false);

        this._socket.send(JSON.stringify({action_type: "beaten"}));
    },

    //Служебные функции

    _resizeOpponentLayer: function ()
    {
        let cardsCount = this._opponentLayer.children.length;
        this._opponentCardsNumberLayer.updateText(cardsCount);

        if (cardsCount === 0)
            return;

        let cardWidth = this._opponentLayer.children[0].width;

        let margin = Math.min(10,
            (Math.min(this.width * 0.9, GameLayer.OPPONENT_CARDS_LAYER_MAX_WIDTH) - cardWidth * cardsCount) / (cardsCount - 1));

        for (let i = 0; i < cardsCount; ++i)
            this._opponentLayer.children[i].getLayoutParameter().setMargin(0, 0, margin, 0);

        this._opponentLayer.setContentSize(cardWidth * cardsCount + margin * (cardsCount - 1), this._opponentLayer.height);
        this.forceDoLayout();
    },

    _updateBeatenCards: function ()
    {
        let beatenCount = 36 - dd.StackLength - this._cardsLayer.children.length - this._opponentLayer.children.length; // todo: баг с бито
        console.log("@@", beatenCount, dd.StackLength, this._cardsLayer.children.length, this._opponentLayer.children.length)

        for (let i = 0; i < Math.min(this._beatenSprites.length, beatenCount); i++)
            this._beatenSprites[i].setVisible(true);
    },

    _updateStackSprites: function ()
    {
        if (dd.StackLength < this._stackSprites.length)
        {
            let back = this._stackSprites.pop();
            back.removeFromParent();
        }
    },

    _addPlayerCard: function (rank, suit)
    {
        this._cardsLayer.addCard(rank, suit);
    },

    _addOpponentCard: function ()
    {
        let card = new ccui.Layout();

        let cardSprite = new cc.Sprite("Cards/CardBack.png");
        cardSprite.setAnchorPoint(cc.p(0,0));
        cardSprite.setPosition(cc.p(0,0));

        card.addChild(cardSprite, -1);
        card.setContentSize(cardSprite.width, cardSprite.height);

        let cardAlign = new ccui.LinearLayoutParameter();
        cardAlign.setGravity(ccui.LinearLayoutParameter.CENTER_VERTICAL);

        card.setLayoutParameter(cardAlign);

        this._opponentLayer.addChild(card);
        this._resizeOpponentLayer();
    },

    _opponentMoveBegin: function (target, card)
    {
        let pos = this._opponentLayer.getPosition();
        pos.x += this._opponentLayer.width - card.width;

        card.removeFromParent();
        cc.director.getRunningScene().addChild(card);
        card.setPosition(pos);

        let lastCard = this._opponentLayer.children.pop();
        lastCard.removeFromParent();

        this._resizeOpponentLayer();
    },

    // Анимированные действия

    _opponentsMove: function (rank, suit)
    {
        let card = new dui.Card(rank, suit);

        let moveStart = cc.callFunc(this._opponentMoveBegin.bind(this), this, card);

        let newPos = this._table.getLastCardPosition();

        let moveCard = cc.targetedAction(card, cc.moveTo(dd.SYSTEM_ANIMATION_DELAY * 2, newPos));

        let moveEnd = cc.callFunc(function (){
            card.removeFromParent();
            this._table.addCard(card);
            this.forceDoLayout();

            if (dd.GameState !== dd.GameStates.Waiting)
                this._takeButton.setVisible(true);

            if (this._table.getNotBeatenCardsCount() === 1)
                this._resetTimer(true);

        }.bind(this), this);

        this.runAction(cc.sequence(moveStart, moveCard, moveEnd));
    },

    _opponentsResponse: function (attack_card, defend_card)
    {
        let card = new dui.Card(defend_card[0], defend_card[1]);

        let moveStart = cc.callFunc(this._opponentMoveBegin.bind(this), this, card);

        let newPos = this._table.getCardPosition(attack_card[0], attack_card[1]);

        let moveCard = cc.targetedAction(card, cc.moveTo(dd.SYSTEM_ANIMATION_DELAY * 2, newPos));

        let moveEnd = cc.callFunc(function (){
            card.removeFromParent();
            this._table.findCardOnTable(attack_card[0], attack_card[1]).addGreaterCard(card);
            
            if (this._table.getNotBeatenCardsCount() === 0)
            {
                this._resetTimer(true);
                this._beatenButton.setVisible(true);
            }
        }.bind(this), this);

        this.runAction(cc.sequence(moveStart, moveCard, moveEnd));
    },

    _playerTakesCard: function (rank, suit)
    {
        let newPos = this._cardsLayer.getPosition();
        newPos.x += this._cardsLayer.width - dui.CardWidth;

        this._runTakeCardAnimation(newPos, this._addPlayerCard.bind(this, rank, suit));
    },

    _opponentTakesCard: function ()
    {
        let newPos = this._opponentLayer.getPosition();
        newPos.x += this._opponentLayer.width - dui.CardWidth;

        this._runTakeCardAnimation(newPos, this._addOpponentCard.bind(this));
    },

    // Анимации
    
    _runTakeCardAnimation: function (pos, callback)
    {
        --dd.StackLength; // есть проблемы с колвом карт в бито, т.к. при dealing стек успевает уменьшиться
        let cardBack = new cc.Sprite("Cards/CardBack.png");

        cardBack.setAnchorPoint(cc.p(0, 0));
        cardBack.setPosition(cc.p(GameLayer.BORDER, this.height / 2));

        let moveStart = cc.callFunc(function (){
            this._updateStackSprites();
            this.addChild(cardBack);
        }.bind(this), this);

        let moveCard = cc.targetedAction(cardBack, cc.moveTo(dd.SYSTEM_ANIMATION_DELAY * 3, pos));

        let moveEnd = cc.callFunc(function () {
            cardBack.removeFromParent();
            callback();
        }.bind(this), this);

        this.runAction(cc.sequence(moveStart, moveCard, moveEnd));
    },

    _runBeatenAnimation: function ()
    {
        let cardAnimations = this._table.getCardAnimations(TableLayer.AnimationTypes.Beaten, cc.p(this.width, this.height / 2));

        if (cardAnimations.length === 0)
            return;

        let endMoves = cc.callFunc(function (){
            this._updateBeatenCards();
            this._table.clean();
        }.bind(this), this);

        this.runAction(cc.sequence(cc.spawn(cardAnimations), endMoves));
    },

    _runPlayerTakesAnimation: function ()
    {
        let pos = this._cardsLayer.getPosition();
        pos.x += this._cardsLayer.width;

        let self = this;

        let cardAnimations = this._table.getCardAnimations(TableLayer.AnimationTypes.TakeAll, pos, function (target, card) {
            self._addPlayerCard(card.rank, card.suit);

            if (card.greaterCard)
                self._addPlayerCard(card.greaterCard.rank, card.greaterCard.suit);

            self.forceDoLayout();
        });

        let endMoves = cc.callFunc(this._table.clean.bind(this._table), this);

        this.runAction(cc.sequence(cc.spawn(cardAnimations), endMoves));
    },

    _runOpponentTakesAnimation: function ()
    {
        let pos = this._opponentLayer.getPosition();
        pos.x += this._opponentLayer.width;

        let self = this;

        let cardAnimations = this._table.getCardAnimations(TableLayer.AnimationTypes.TakeAll, pos, function (target, card) {
            self._addOpponentCard();

            if (card.greaterCard)
                self._addOpponentCard();

            self.forceDoLayout();
        });

        let endMoves = cc.callFunc(this._table.clean.bind(this._table), this);

        this.runAction(cc.sequence(cc.spawn(cardAnimations), endMoves));
    },
});

GameLayer.CARDS_LAYER_HEIGHT = 200;
GameLayer.CARDS_LAYER_MAX_WIDTH = 800;
GameLayer.OPPONENT_CARDS_LAYER_MAX_WIDTH = 500;
GameLayer.BORDER = 10;
GameLayer.CARDS_LAYER_MARGIN_BOTTOM = 10;
GameLayer.CARDS_SEPARATOR_Y = 10;
GameLayer.CARDS_SEPARATOR_X = 20;
GameLayer.OPPONENT_LAYER_HEIGHT = 200;
GameLayer.OPPONENT_LAYER_MARGIN_TOP = -60;
GameLayer.CARDS_ON_TABLE_HEIGHT = 210;

let GameScene = cc.Scene.extend({
    ctor: function(player1, player2, socket)
    {
        this._super();

        let layer = new GameLayer(player1, player2, socket);
        this.addChild(layer);
    },

    onEnter: function ()
    {
        this._super();
    }
});