/**
 * Created by Vika O on 01.11.2024.
 */
/**
 * System animation delay.
 * @const
 * @type {number}
 * @default
 */
dd.SYSTEM_ANIMATION_DELAY = 0.4;
/**
 * A default bound for placing elements on screen.
 * @const
 * @type {number}
 * @default
 */
dd.SCREEN_BOUND = 15.0;
/**
 * Maximum animation speed.
 * @const
 * @type {number}
 * @default
 */
dd.MAX_ANIMATION_SPEED = 0.8;
/**
 * Medium animation speed.
 * @const
 * @type {number}
 * @default
 */
dd.MEDIUM_ANIMATION_SPEED = 0.4;
/**
 * Small animation speed.
 * @const
 * @type {number}
 * @default
 */
dd.SMALL_ANIMATION_SPEED = 0.2;
/**
 * Minimum animation speed
 * @const
 * @type {number}
 * @default
 */
dd.MIN_ANIMATION_SPEED = 0.07;

/**
 * DPI factor.
 * @see cc.game.onStart
 * @const
 * @type {number}
 * @default
 */
dd.MOBILE_DPI_FACTOR = 160.0;

/**
 * Tablet or phone.
 * @see cc.game.onStart
 * @const
 * @type {number}
 * @default
 */
dd.DEVICE_WIDTH_INDICATOR = 5.0;

/**
 * Target retina DPI for tablets.
 * @see cc.game.onStart
 * @const
 * @type {number}
 * @default
 */
dd.TABLET_RETINA_TARGET_DPI = 264.0;

/**
 * Target retina width for tablets.
 * @see cc.game.onStart
 * @const
 * @type {number}
 * @default
 */
dd.TABLET_RETINA_TARGET_WIDTH = 2048.0;

/**
 * Target retina height for tablets.
 * @see cc.game.onStart
 * @const
 * @type {number}
 * @default
 */
dd.TABLET_RETINA_TARGET_HEIGHT = 1536.0;

/**
 * Target retina DPI for phones.
 * @see cc.game.onStart
 * @const
 * @type {number}
 * @default
 */
dd.PHONE_RETINA_TARGET_DPI = 458.0;

/**
 * Target retina width for phones.
 * @see cc.game.onStart
 * @const
 * @type {number}
 * @default
 */
dd.PHONE_RETINA_TARGET_WIDTH = 2358.0;

/**
 * Target retina height for phones.
 * @see cc.game.onStart
 * @const
 * @type {number}
 * @default
 */
dd.PHONE_RETINA_TARGET_HEIGHT = 750.0;

/**
 * Minimum display width to recognize retina for desktop platform.
 * @see cc.game.onStart
 * @const
 * @type {number}
 * @default
 */
dd.DESKTOP_RETINA_DISPLAY_MIN_WIDTH = 2400;
/**
 * Minimum display width to recognize retina.
 * @see cc.game.onStart
 * @const
 * @type {number}
 * @default
 */
dd.IPHONE_BIG_DISPLAY_MIN_WIDTH = 2200;
/**
 * Minimum distance to detect drag or click.
 * @const
 * @type {Number}
 * @default
 */
dd.TOUCH_CRITICAL_DISTANCE = 5;

/**
 * Version of settings file format
 * @const
 * @type {number}
 * @default
 */
dd.SETTINGS_VERSION = 260;

/**
 * Enum for user types
 * @enum {String}
 */
dd.USER_TYPE = {
	ADMIN: "admin",
	TEACHER: "teacher",
	USER: "user"
};

/**
 * All game settings are stored here.
 * @namespace
 */
dd.settings = {
	/**
     * @private
     * @default
     */
	_cacheDir: "Cache",
	/**
     * Global font definition.
     * @type {cc.FontDefinition}
     * @default
     */
	fontDefinition: new cc.FontDefinition(),
	/**
     * Global font name.
     * @type {String}
     * @default
     */
	fontName: dd.fonts.getRealFontName("Comfortaa-Regular"),
	/**
	 * Global font name in bold style.
	 * @type {String}
	 * @default
	 */
	fontBoldName: dd.fonts.getRealFontName("Comfortaa-Bold"),
	/**
	 * Global font name in light style.
	 * @type {String}
	 * @default
	 */
	fontLightName: dd.fonts.getRealFontName("Comfortaa-Light"),
	/**
	 * Global font size.
	 * @type {Number}
	 * @default
	 */
	fontSize: 20,
	/**
     * Global transparent color.
     * @type {cc.Color}
     * @default
     */
	transparentColor: cc.color(0, 0, 0, 80),

	/**
	 * Level font transparent color.
	 * @type {cc.Color}
	 * @default
	 */
	levelFontColor: cc.color(0, 102, 102),

	/**
     * Is user logged in editor.
     * @type {Boolean}
     * @default
     */
	userLoggedIn: false,

	/**
     * If display is retina.
     * @type {Boolean}
     * @default
     */
	isRetinaEnabled: false,

	/**
	 * Screen bounds
	 * @private
     */
	_screenBounds: null,
	/**
	 * Loads settings.
	 */
	load: function()
	{
		if(dd.fileUtils.isFileExist(this._getSettingsPath()))
		{
			var fileContent = dd.fileUtils.readObject(this._getSettingsPath());

			if(fileContent.version < dd.SETTINGS_VERSION)
			{
				dd.fileUtils.removeFile(this._getSettingsPath());
				this.save();
			}
			else
			{
				this._cache = fileContent;
			}
		}
		else
		{
			dd.fileUtils.createDirectory(dd.fileUtils.getWritablePath());

			this._loadDefaultCache();

			this.save();
		}

		this.fontDefinition.fontName = this.fontName;
		this.fontDefinition.fontSize = this.fontSize;
	},

	_cache: {},

	_loadDefaultCache: function()
	{
		this._cache = {
			version: dd.SETTINGS_VERSION,
			animationSettings: {animationSpeed: 0.5},
			languageSettings: {language: cc.sys.language},
			soundSettings: {
				backgroundVolume: 0.99,
				effectVolume: 0.99,
				soundEnabled: 1
			},
			userSettings: {
				userAutoLogin: false,
				accessToken: "",
				refreshToken: "",

				userData: {
					id: "",
					login: "",
					accountType: "",
					username: "",

					firstName: "",
					surname: ""
				}
			},
			appScale: null,
			deviceName: null,
			modifyDate: null
		};
	},

	/**
	 * Returns a path to settings.
	 * @returns {string}
	 * @private
	 */
	_getSettingsPath: function()
	{
		return dd.fileUtils.getWritablePath() + "/Settings.json";
	},
	/**
	 * Saves settings to file.
	 */
	save: function()
	{
		dd.fileUtils.saveObject(this._getSettingsPath(), this._cache);
	},
	/**
	 * Returns access token for current user.
	 * @returns {String}
	 */
	getAccessToken: function()
	{
		return this._cache.userSettings.accessToken;
	},
	/**
	 * Sets access token for current user.
	 * @param {String} token Access token.
	 */
	setAccessToken: function(token)
	{
		this._cache.userSettings.accessToken = token;
		this.save();
	},
	/**
	 * Returns refresh token for current user.
	 * @returns {String}
	 */
	getRefreshToken: function()
	{
		return this._cache.userSettings.refreshToken;
	},
	/**
	 * Sets refresh token for current user.
	 * @param {String} token Refresh token.
	 */
	setRefreshToken: function(token)
	{
		this._cache.userSettings.refreshToken = token;
		this.save();
	},

	/**
	 * Returns a screen size.
	 * @returns {cc.Size}
	 */
	getScreenSize: function()
	{
		return cc.director.getWinSize();
	},

	/**
	 * Returns app scale.
	 * @returns {Float}
	 */
	getAppScale: function()
	{
		return this._cache.appScale;
	},

	/**
	 * Sets app scale.
	 * @param {Float} scale
	 */
	setAppScale: function(scale)
	{
		this._cache.appScale = scale;
		this.save();
	},
	/**
	 * Returns language code.
	 * @returns {String}
	 */
	getLanguage: function()
	{
		return this._cache.languageSettings.language;
	},
	/**
	 * Sets language code.
	 * @param {String} value Language code.
	 */
	setLanguage: function(value)
	{
		this._cache.languageSettings.language = value;
		this.save();
	},

	/**
	 * Returns a safe area rect on screen.
	 * @returns {cc.Rect}
	 */
	getSafeAreaRect: function()
	{
		if(!cc.sys.isNative)
		{
			var screenSize = this.getScreenSize();

			return {
				x: 0,
				y: 0,
				width: screenSize.width,
				height: screenSize.height
			};
		}

		return cc.director.getOpenGLView().getSafeAreaRect();
	},
	/**
	 * Returns screen bounds
	 * @returns {{safeAreas: {top: boolean, left: boolean, bottom: boolean, right: boolean}, top: number, left: number, bottom: number, right: number}}
	 */
	getScreenBounds: function()
	{
		if(!this._screenBounds)
		{
			var safeAreaRect = this.getSafeAreaRect();
			var screenSize = this.getScreenSize();

			var safeAreas = {
				top: false,
				left: safeAreaRect.x !== 0,
				bottom: safeAreaRect.y !== 0,
				right: safeAreaRect.width + safeAreaRect.x < screenSize.width
			};

			this._screenBounds = {
				safeAreas: safeAreas,
				top: dd.SCREEN_BOUND,
				left: safeAreas.left ? safeAreaRect.x : dd.SCREEN_BOUND,
				bottom: safeAreas.bottom ? safeAreaRect.y : dd.SCREEN_BOUND,
				right: safeAreas.right ? screenSize.width - safeAreaRect.x - safeAreaRect.width : dd.SCREEN_BOUND
			};
		}

		return this._screenBounds;
	},
};
