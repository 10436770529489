/**
 * Created Vika O.
 */

/**
 * @class Start page of dd.
 * @extends ccui.Layout
 */
let StartMenuLayer = ccui.Layout.extend(/** @lends StartMenuLayer# */{

    _loginButton: null,
    _marketButton: null,

    _userLayer: null,
    _upperButtonLayer: null,
    _lowerButtonLayer: null,

    _userLogin: null,

    ctor: function ()
    {
        this._super();

        this.setLayoutType(ccui.Layout.RELATIVE);
        this.setContentSize(dd.settings.getScreenSize());

        this._drawUserLayout();
    },

    _drawMenuButtons: function()
    {

    },

    _drawUserLayout: function()
    {
        this._userLayer = new ccui.Layout();
        this._userLayer.setBackGroundImage("Background/EndGameBackground.png");
        this._userLayer.setBackGroundImageScale9Enabled(true);
        this._userLayer.setLayoutType(ccui.Layout.LINEAR_VERTICAL);
        this._userLayer.setContentSize(300, 400);

        let userAlign = new ccui.RelativeLayoutParameter();
        userAlign.setAlign(ccui.RelativeLayoutParameter.CENTER_IN_PARENT);

        this._userLayer.setLayoutParameter(userAlign);

        this._userLogin = new dui.TextInput("", "Login", dd.settings.fontSize, 200, 24);

        let loginAlign = new ccui.LinearLayoutParameter();
        loginAlign.setGravity(ccui.LinearLayoutParameter.CENTER_HORIZONTAL);
        loginAlign.setMargin(0, 20, 0, 20);
        this._userLogin.setLayoutParameter(loginAlign);

        this._userLayer.addChild(this._userLogin);

        let playButton = new dui.Button(dd.spriteUtils.getButtonName("play", dd.NORMAL_STATE),
            dd.spriteUtils.getButtonName("play", dd.SELECTED_STATE),
            dd.spriteUtils.getButtonName("play", dd.DISABLED_STATE),
            ccui.Widget.LOCAL_TEXTURE);
        playButton.addClickEventListener(this.callback.bind(this));

        let playButtonAlign = new ccui.LinearLayoutParameter();
        playButtonAlign.setGravity(ccui.LinearLayoutParameter.CENTER_HORIZONTAL);
        playButton.setLayoutParameter(playButtonAlign);

        this._userLayer.addChild(playButton);

        // let scale = dd.settings.getScreenSize().height / this._userLayer.height;
        //
        // if (scale > 1)
        //     scale = 1;

        this.addChild(this._userLayer, 2);
        // this._loginButton = new dui.Button(dd.spriteUtils.getIconName("quit", dd.SELECTED_STATE),
        //     dd.spriteUtils.getIconName("quit", dd.NORMAL_STATE),
        //     dd.spriteUtils.getIconName("quit", dd.DISABLED_STATE),
        //     ccui.Widget.PLIST_TEXTURE);
        //
        // this._loginButton.setRotation(45);
        // this._loginButton.addClickEventListener(function(){});
        //
        // let boundingBox = this._loginButton.getBoundingBox();
        // if (!cc.sys.isNative)
        // {
        //     boundingBox.width *= Math.sqrt(2);
        //     boundingBox.height *= Math.sqrt(2);
        // }
        //
        // this._upperButtonLayer.setContentSize(boundingBox.width, boundingBox.height);
        //
        // this._loginButton.setPosition(boundingBox.width / 2, boundingBox.height / 2);
        // this._upperButtonLayer.addChild(this._loginButton);
    },

    callback: function ()
    {
        const protocol = window.location.protocol === "https:" ? "wss://" : "ws://";
        const host = window.location.host; // Домен или IP // dd.appConfig.apiAddress
        // const host = "localhost:8000";

        console.log("play", this._userLogin.getString());

        const socket = new WebSocket(`${protocol}${host}/ws/${this._userLogin.getString()}`);

        // Connection opened
        socket.addEventListener("open", (event) => {
            console.log(event)

            let trans = new cc.TransitionFade(1.5 * dd.SYSTEM_ANIMATION_DELAY,
                new GameScene(socket));
            cc.director.runScene(trans);
        });

        socket.addEventListener("close", (event) => {
            console.log("SocketClosed!", event)
        });
    }
});

StartMenuLayer.SEPARATOR = 15;
StartMenuLayer.MENU_INDENTATION = 90;
StartMenuLayer.LOGINLAYER_TAG = 2;
StartMenuLayer.CONFIRM_LAYER_TAG = 4872;
StartMenuLayer.MIST_SHIFT = 30;
StartMenuLayer.BORDER = 15;
StartMenuLayer.RIGHT_BAR_WIDTH = 141.4;

/**
 * @class Scene for {@link StartMenuLayer}
 * @extends cc.Scene
 * @constructor
 */
let StartMenuScene = cc.Scene.extend(/** @lends StartMenuScene# */{
    ctor: function ()
    {
        this._super();

        let layer = new StartMenuLayer();

        this.addChild(layer);

        let backLayer = dd.backgroundUtils.generateBackground();
        this.addChild(backLayer, -1);

        if (!cc.sys.isNative)
            window.location.hash = "";
    }
});
