/**
 * Created by Vika O on 01.11.2024.
 */

/**
 * A variable containing all localizations of strings.</br>
 * If not null: {"localization_key":"localized_string"}
 * @type {?Object}
 * @private
 * @default
 */
var _localizedStrings = null;

/**
 * A variable containing all localizations of images.</br>
 * If not null: {"localization_key":"localized_image"}
 * @type {?Object}
 * @private
 * @default
 */
var _localizedImages = null;

dd.LANGUAGE_PREFIXES = {};
dd.LANGUAGE_PREFIXES[cc.sys.LANGUAGE_RUSSIAN] = "";
dd.LANGUAGE_PREFIXES[cc.sys.LANGUAGE_ENGLISH] = "en-";

/**
 * Removes all localized strings.
 */
var ResetLanguage = function()
{
	_localizedStrings = null;
	_localizedImages = null;
};

/**
 * Returns localized string for key.
 * @param {String} key Localized string key
 * @returns {String}
 */
var LocalizedString = function(key)
{
	if(!_localizedStrings)
	{
		var language = dd.settings.getLanguage();

		_localizedStrings = cc.loader.getRes("localizations/Localized-" + language + ".json");
	}
	if(_localizedStrings.hasOwnProperty(key))
		return _localizedStrings[key];
	else
		return key;
};

/**
 * Returns localized image for key.
 * @param {String} key Localized string key
 * @returns {String}
 */
var LocalizedImage = function(key)
{
	if(!_localizedImages)
	{
		var language = dd.settings.getLanguage();

		_localizedImages = {};
	}
	if(_localizedImages.hasOwnProperty(key))
		return _localizedImages[key];
	else
		return key;
};
