/**
 * A brief explanation for "project.json":
 * Here is the content of project.json file, this is the global configuration for your game, you can modify it to customize some behavior.
 * The detail of each field is under it.
 {
 "project_type": "javascript",
 // "project_type" indicate the program language of your project, you can ignore this field

 "debugMode"     : 1,
 // "debugMode" possible values :
 //      0 - No message will be printed.
 //      1 - cc.error, cc.assert, cc.warn, cc.log will print in console.
 //      2 - cc.error, cc.assert, cc.warn will print in console.
 //      3 - cc.error, cc.assert will print in console.
 //      4 - cc.error, cc.assert, cc.warn, cc.log will print on canvas, available only on web.
 //      5 - cc.error, cc.assert, cc.warn will print on canvas, available only on web.
 //      6 - cc.error, cc.assert will print on canvas, available only on web.

 "showFPS"       : true,
 // Left bottom corner fps information will show when "showFPS" equals true, otherwise it will be hide.

 "frameRate"     : 60,
 // "frameRate" set the wanted frame rate for your game, but the real fps depends on your game implementation and the running environment.

 "id"            : "gameCanvas",
 // "gameCanvas" sets the id of your canvas element on the web page, it's useful only on web.

 "renderMode"    : 0,
 // "renderMode" sets the renderer type, only useful on web :
 //      0 - Automatically chosen by engine
 //      1 - Forced to use canvas renderer
 //      2 - Forced to use WebGL renderer, but this will be ignored on mobile browsers

 "engineDir"     : "frameworks/cocos2d-html5/",
 // In debug mode, if you use the whole engine to develop your game, you should specify its relative path with "engineDir",
 // but if you are using a single engine file, you can ignore it.

 "modules"       : ["cocos2d"],
 // "modules" defines which modules you will need in your game, it's useful only on web,
 // using this can greatly reduce your game's resource size, and the cocos console tool can package your game with only the modules you set.
 // For details about modules definitions, you can refer to "../../frameworks/cocos2d-html5/modulesConfig.json".

 "jsList"        : [
 ]
 // "jsList" sets the list of js files in your game.
 }
 *
 */

function loadApp()
{
    cc.game.onStart = function ()
    {
        cc.log("durak");
        
        cc.director.setProjection(cc.Director.PROJECTION_2D);

        if (cc.sys.isMobile &&
            cc.sys.browserType !== cc.sys.BROWSER_TYPE_BAIDU &&
            cc.sys.browserType !== cc.sys.BROWSER_TYPE_WECHAT)

            cc.view.enableAutoFullScreen(true);

        dd.settings.isRetinaEnabled = false;
        var scale = 1.0;

        if (cc.sys.platform === cc.sys.DESKTOP_BROWSER)
        {
            dd.settings.isRetinaEnabled = (window.devicePixelRatio > 1 ||
                (window.matchMedia && window.matchMedia("(-webkit-min-device-pixel-ratio: 1.5),(-moz-min-device-pixel-ratio: 1.5),(min-device-pixel-ratio: 1.5)").matches)
            );
        }
        else if (cc.sys.platform === cc.sys.MOBILE_BROWSER)
        {
            dd.settings.isRetinaEnabled = true;
        }
        
        cc.view.enableRetina(dd.settings.isRetinaEnabled);

        if (dd.settings.isRetinaEnabled)
            cc.director.setContentScaleFactor(2.0);

        console.log("Is retina:" + dd.settings.isRetinaEnabled + ", scale:" + scale);

        dd.loadClassMapping();

        dd.settings.load();

        if (!dd.settings.getAppScale())
            dd.settings.setAppScale(scale);

        cc.view.setDesignResolutionSize(cc.view.getFrameSize().width / dd.settings.getAppScale(), cc.view.getFrameSize().height / dd.settings.getAppScale(), cc.ResolutionPolicy.SHOW_ALL);
        cc.view.adjustViewPort(true);
        cc.view.resizeWithBrowserSize(true);

        var resFile = dd.settings.isRetinaEnabled ? "res/pre-resources-hd.plist" : "res/pre-resources.plist";

        if (!cc.sys.isNative)
        {
            var loadRes = [];

            for (var key in dd.fonts)
            {
                if (cc.isObject(dd.fonts[key]))
                    loadRes.push(dd.fonts[key]);
            }

            cc.loader.load(loadRes, function () {
                cc.loader.loadAliases(resFile, function () {
                    if (document.getElementById("cocosLoading")) //If referenced loading.js, please remove it
                        document.body.removeChild(document.getElementById("cocosLoading"));

                    cc.director.runScene(new LoadScene());
                });
            });
        }
        else
        {
            cc.loader.loadAliases(resFile, function ()
            {
                cc.director.runScene(new LoadScene());
            });
        }
    };

    cc.game.run();
}

loadApp();