/**
 * Created by Nikita Besshaposhnikov on 15.03.17.
 */

/**
 * This namespace contains functions for network features
 * @namespace
 *
 */
dd.networkUtils =
{
	_broadcastServerStarted: false,
	_broadcastClientStarted: false,

	_ignoreRequests: false,

	isNetworkGame: false,
	inGame: false,

	_networkGame: null,

	_networkDebugClient: null,



};

dd.NetworkGameState = {
	ProcessingLevel: "NetProcessingLevel",
	Broken: "NetRobotBroken",
	Win: "NetTaskCompleted",
	Loose: "NetTaskFailed",
	Develop: "NetDevelopProgram",
	ReadyToChangeLevel: "NetReadyToChange"
};

dd.NetworkDebugSendTypes = {
	DISCOVER: 0,
	BROADCAST_DATA: 1,
	TEACHER_SERVER: 2,
	STUDENT_CLIENT: 3,
	GAME_SERVER: 4,
	GAME_CLIENT: 5,
	OTHER: 6
};
